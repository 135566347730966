<template>
	<control-panel :choosedLabel="choosedLabel" :zoom="zoom" :factor="factor" :ref="'controlpanel'"></control-panel>
	<section class="editor col-8" ref="container" @click="deselectObjects($event)">
		<cart-bar :is_admin_edit="is_admin_edit" :shape="settedShape" ref="cart"></cart-bar>
		<toolbar 
			ref="toolbar"
			:history="history"
			:activeEditor="activeEditor"
			:is_admin="is_admin"
			:is_admin_edit="is_admin_edit"></toolbar>
		<div class="canvas-block" :style="{ display: this.$side == 'front' ? 'block' : 'none' }">
			<template v-if="loading">
				<div class="flex justify-content-center preloading-block">
					<div class="text-saving">wait...					
						<ProgressSpinner style="display: block;" animationDuration=".5s" strokeWidth="8"/>
					</div>
				</div>
			</template>
			<canvas ref="editorFront" style="background-color: transparent"></canvas>
		</div>
		<div class="canvas-block" :style="{ display: this.$side == 'back' ? 'block' : 'none' }">
			<template v-if="loading">
				<div class="flex justify-content-center preloading-block">
					<div class="text-saving">wait...					
						<ProgressSpinner style="display: block;" animationDuration=".5s" strokeWidth="8"/>
					</div>
				</div>
			</template>
			<canvas ref="editorBack" style="background-color: transparent;"></canvas>
		</div>
		
		<shapes ref="shapes"></shapes>
		<images-upload ref="uploadsImage"></images-upload>
		<graphics :ref="'graphics'"></graphics>
		<customer-labels ref="loadCustomerLabel" :labels="customer.labels" :settedShape="settedShape"></customer-labels>	
		<save-label ref="saveLabelDialog" :label="settedShape.product_params"></save-label>
		<save-product :product_data="settedShape.product_params" :ref="'saveProduct'"></save-product>
		<auth-pop-up ref="auth"></auth-pop-up>
		<alert-message ref="alertMessage" :message="message"></alert-message>
		
	</section> <layer-actions ref="actionsLayer" :choosedLabel="choosedLabel" :history="history"></layer-actions>	
	<layers-component :ref="'layer'" :settedShape="settedShape"></layers-component>	
</template>
<script>
import axios from 'axios';
import {mapState, mapActions, mapMutations} from 'vuex';
import { isProxy, toRaw, ref } from 'vue';

import ToolBar from '../navigation/ToolBar';
import CartBar from '../navigation/CartBar';
import ShapesList from '../ShapesList';
import ImagesCollection from '../ImagesCollection';
import UploadImage from '../UploadImage';
import GalleryComponent from '../GalleryComponent';
import GraphicsComponent from '../GraphicsComponent';
import LabelsComponent from '../LabelsComponent';
import SaveLabel from '../SaveLabel';
import SaveProduct from '../SaveProduct';
import LayerActions from '../LayerActions';
import LayersComponent from "./LayersComponent";
import ControlPanel from "./ControlPanel";
import AuthPopUp from '../profile/AuthPopUp';
import AlertMessage from '../alerts/AlertMessage';
import WebFontLoader from 'webfontloader';

import {changeDpiDataUrl} from 'changedpi'


const layer = ref(0);

export default {
	name: "EditorComponent",
	// props:['selectedProduct'],
	props:{
		selectedProduct: {
			type: String,
			required: true
		}
	},
	components: {
		toolbar: ToolBar,
		cartBar: CartBar,
		shapes: ShapesList,
		images: ImagesCollection,
		imagesUpload: UploadImage,
		imagesGallery: GalleryComponent,
		graphics: GraphicsComponent,
		customerLabels: LabelsComponent,
		saveLabel: SaveLabel,
		saveProduct: SaveProduct,
		layerActions: LayerActions,
		LayersComponent: LayersComponent,
		ControlPanel: ControlPanel,
		AuthPopUp: AuthPopUp,
		AlertMessage: AlertMessage
	},
	data() {
		return {
			loading: false,
			zoom: {
				front: 0,
				back: 0
			},
			factor: 1.05,
			// canvasWidth: 800,
			// canvasHeight: 800,
			displayBasic: false,
			message:{},
			object: {
				moved: false,
			},
			is_admin: false,
			is_admin_edit: false,
			userEmail: null,
			userActiveDate: null,
			activeEditor: 'front',
			inchesToPixels: 96,
			container: null,
			shape: null,
			defaults: {
				patternSourceCanvas: null,
				patternImage: '',
				patternPadding: 0,
				patternShape: {},
				Canvas: {
					// width: 700,
					// height: 700
				},				
				Image: {
					scaleX: 1,
					scaleY: 1,
					left: 125,
					top: 125,
					lockMovementX: false,
					lockMovementY: false,
					lockScalingX: false,
					lockScalingY: false,
					hasRotatingPoint: true,
					// calculatePositionX: 0,
					// calculatePositionY: 0,
				},
				Text: {
					text: 'Curved Text',
					// lockScalingX: true,
					isCreate: true,
					lockScalingY: true,
					// fontSize: 18,
					flipped: false,
					//diameter: 250,
					kerning: 0,
					textAlign: 'center',
					fontFamily: 'LibreBaskerville',
					// calculatePositionX: 0,
					// calculatePositionY: 0,
					fill: '#000000',
					fontStyle: 'normal',
					fontWeight: 400,
					lineHeight: 1,
					charSpacing: 1,
					letterHeight: 1,
					underline: false,
					textDecoration: 'underline',
					// diameter: 360,
					strokeWidth: 0,
					strokeStyle: '#000',
					stroke: '#000000',					
					// left: 100,
					// top: 100,
					strokeDashArray: [0, 0],
					hasShadow: false,
					shadow: {
						color: '#000000',
						offsetX: 0,
						offsetY: 0,
						opacity: 0,
						blur: 0
					}
				},
				Border: {
					strokeUniform: true,
					stroke: '#000000',
					strokeWidth: 10,
					scaleX: 1,
					scaleY: 1,
					strokeOffset: 0,
					hasShadow: false,
					shadow: {
						color: '#000000',
						offsetX: 0,
						offsetY: 0,
						opacity: 0,
						blur: 0
					}
				},
				Common: {
					strokeUniform: true,
					transparentCorners: false,
					preserveObjectStacking: true,
					cornerStyle: 'circle',
					cornerSize: 18,
					globalCompositeOperation: 'source-atop'
				}				
			},			
			settedShape: {
				material_type: '',
				product_params: {
					name: '',
					category: 0,
					product_id: 0,
					master_product_id: 0
				},
				front: {
					backgrounds_shape: 'Rectangle',
					prefixName: '3.3x4',
					fill: '#FFFFFF',
					width: 3.3,
					height: 4
				},
				back: {
					backgrounds_shape: 'Rectangle',
					prefixName: '2x2.4',
					fill: '#FFFFFF',
					width: 2,
					height: 2.4,
					isEmpty: true
				}
			},
			history: {
				front: {
					current: [],
					trash:[],
					last: []
				},
				back: {
					current: [],
					trash:[],
					last: []
				}
			},
			historyColection: {//using for redo undo for product, must be collection layers
				front: [],
				back: []
			},
			customer: {
				labels: []
			},
			choosedLabel: {
				x: 0,
				y: 0,
				show: 'none',
				is_text: false
			},
			canvases: {
				front: {
					canvas: this.data.$canvasFront,
					editor: this.data.$editorFront,
					shape: this.data.$shapeFront,
					cuttingLine: this.data.$cuttingLineFront,
					cuttingLineDashed: this.data.$cuttingLineDashedFront,
					settedShape: 'Rectangle',
					scaleRatio: this.data.$scaleRatioFront
				},
				back: {
					canvas: this.data.$canvasBack,
					editor: this.data.$editorBack,
					shape: this.data.$shapeBack,
					cuttingLine: this.data.$cuttingLineBack,
					cuttingLineDashed: this.data.$cuttingLineDashedBack,
					settedShape: 'Rectangle',
					scaleRatio: this.data.$scaleRatioBack
				}
			},
			
		}
	},
	created() {
    WebFontLoader.load({
      custom: {
        // Use FVD notation to include families https://github.com/typekit/fvd
        families: ["Agency",
				"Alex Brush",
				"Alger",
				"Allura",
				"Anton",
				"Arcade",
				"Asap condensed",
				"Attic",
				"Bad script",
				"Bell",
				"Blackchance",
				"Broadway",
				"Campanile",
				"Cantor",
				"Charlemagne",
				"Chewy",
				"Cinzel",
				"Cloister",
				"Copper Plate",
				"Curlz",
				"Delius",
				"Dymaxion Script",
				"Eccentric",
				"Eluded",
				"Engrave",
				"Fondamento",
				"Frizzy",
				"Great Vibes",
				"Harrington",
				"Hill House",
				"Lemon",
				"LibreBaskerville",
				"Luggie",
				"Lunges",
				"Lovers Quarrel",
				"Macule",
				"Ostrich",
				"Rubik",
				"Saginaw",
				"Scriptina",
				"Seaside",
				"Southampton",
				"Staatliches",
				"Tech",
				"Tusj"],
        // Path to stylesheet that defines font-face
        urls: ['../../../public/css/fonts.css'],
      },
      active: this.setFontLoaded,
    });
  },
	methods: {
		...mapMutations({
			setSide: 'canvas/setSide',
			setShowBackCanvas: 'canvas/setShowBackCanvas',
			setProductName: 'order/setProductName',
			setProductPrice: 'order/setProductPrice',
			setProductImages: 'order/setProductImages',
			setProductSizes: 'order/setProductSizes',
			setProductPreset: 'order/setProductPreset',
			setProductSlug: 'order/setProductSlug',
			setAdminActions: 'order/setAdminActions',
			setAdminEmail: 'order/setAdminEmail',
			setProductStatus: 'order/setProductStatus',
			setStatusIncludeBackLabel: 'order/setStatusIncludeBackLabel'
		}),
		...mapActions({
			setSide: 'canvas/setSide',
			setShowBackCanvas: 'canvas/setShowBackCanvas',
			setProductName: 'order/setProductName',
			setProductPrice: 'order/setProductPrice',
			setProductImages: 'order/setProductImages',
			setProductSizes: 'order/setProductSizes',
			setProductPreset: 'order/setProductPreset',
			setProductSlug: 'order/setProductSlug',
			setAdminEmail: 'order/setAdminEmail',
			setProductStatus: 'order/setProductStatus',
			setStatusIncludeBackLabel: 'order/setStatusIncludeBackLabel'
		}),
		setPatternSizeValue(params) {
			this.patternPadding = parseInt(params, 10);
			this.patternSourceCanvas.setDimensions({
				width: this.patternImage.getScaledWidth() + this.patternPadding,
				height: this.patternImage.getScaledHeight() + this.patternPadding
			});
			this.canvases[this.$store.state.canvas.$side].canvas.requestRenderAll();
		},
		setToTopCuttingLine() {
			let canvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let objs = canvas.getObjects();					
			objs.map((element) => {
				if(element.id && element.id=='cuttingLine') {
					// canvas.moveTo(element, 2);
					canvas.bringToFront(element);
				}
			});
			objs.forEach((element) => {
				if(element.id && element.id=='cuttingLineDashed') {
					// canvas.moveTo(element, 1);
					canvas.bringToFront(element);
				}
			});
		},
		loadFont(name, url) {
			const font = new FontFace(name, `url(${url})`);
			return font.load().then(function (loadedFont) {
				document.fonts.add(loadedFont);
				return loadedFont;
			});
		},
		addIText(data, side, isCreate = true, isDuplicate = false, isDraggable = false, isReturn) {
			let canvas = side ? this.canvases[side].canvas : this.canvases[this.$store.state.canvas.$side].canvas;
			this.data.$rendering++;
			let updateHistory = typeof data.updateHistory == 'undefined' ? true : data.updateHistory;
			let self = this;
			let text = data.text || this.defaults.Text.text;
			let txtData = {...this.defaults.Text, ...this.defaults.Common};
			let zoom = this.zoom[side || this.$store.state.canvas.$side];

			txtData.order = new Date().getTime();
			Object.assign(txtData, data);
			let input = new fabric.Textbox(text, txtData);
			let coords = data.coords || this.getCoords(input);
			Object.defineProperty(input, 'order', {
				value: new Date().getTime(),
				writable: true,
				enumerable: true,
				configurable: true
			});
			input.set('stroke', data.stroke || false);
			input.set('strokeWidth', data.strokeWidth || 0);
			input.set('strokeLineJoin', data.strokeLineJoin || 'round');
			input.set('paintFirst', 'stroke');
			input.set('hasShadow', data.hasShadow || false);
			input.set('fontFamily', txtData.fontFamily);

			input.set('shadow', {					
					'blur': data.shadow?.blur || 0,
					offsetX: data.shadow?.offsetX || 0,
					offsetY: data.shadow?.offsetY || 0,
					affectStroke: data.shadow?.affectStroke || false,
					color: data.shadow?.color || 'white',
					opacity: data.shadow?.opacity || 0		
			});

			input.set('type', 'i-text');
			input.set(coords);
			input.set('width', txtData.width || 200);
			input.set('editable', true);
			input.set('charSpacing', txtData.charSpacing);
			
			canvas.add(input);
			input.on('selected', function () {
				self.emitter.emit('textSelected');
			});
			input.canvas = isProxy(input.canvas) ? toRaw(input.canvas) : input.canvas;
			canvas.setActiveObject(input);

			if (isDuplicate || isReturn) {
				input.scaleX = 1;
				input.scaleY = 1;
				canvas.bringToFront(input);
			}
			input.scaleX *= Math.pow(this.factor, zoom);
			input.scaleY *= Math.pow(this.factor, zoom);

			input.setCoords();

			if (isCreate && input.isCreate) {
				canvas.centerObjectH(input);
				canvas.centerObjectV(input);
				/**
				 * Getting left and top for the shape inside canvas-block 835px X 640px
				 */
				input.set('calculatePositionX', (input.left / canvas.getWidth()) * 100);
				input.set('calculatePositionY', (input.top / canvas.getHeight()) * 100);
			} else {
				input.set('left', (input.left + 10) * Math.pow(this.factor, zoom));
				input.set('top',  (input.top + 10) * Math.pow(this.factor, zoom));
				// input.set('calculatePositionX',  input.calculatePositionX);
				// input.set('calculatePositionY',  input.calculatePositionY);
			} 
			if (isCreate) {
				this.canvasUpdated(updateHistory, side);
			}
			canvas.renderAll();
			// if ('layer' in data) {
			// 	canvas.moveTo(input, data.layer);
			// }
			this.showActionBlock(true);
			this.setToTopCuttingLine();
			this.$refs.cart.setTotalPrice();
		},
		addCurvedText(data, side, setActive = true, isDuplicate = false, isDraggable = false, isReturn) {
			let canvas = (side && side == 'front' || !side && this.$store.state.canvas.$side == 'front') ? this.data.$canvasFront : this.data.$canvasBack;
			this.data.$rendering++;
			let updateHistory = typeof data.updateHistory == 'undefined' ? true : data.updateHistory;
			let self = this;
			let text = data.text || this.defaults.Text.text;
			let txtData = {...this.defaults.Text, ...this.defaults.Common};
			let zoom = this.zoom[side || this.$store.state.canvas.$side];
			Object.defineProperty(txtData, 'order', {
				value: new Date().getTime(),
				writable: true,
				enumerable: true,
				configurable: true
			});
			
			//kerning - width between letters
			Object.assign(txtData, data);
			let curvedText = new fabric.TextCurved(text, txtData);
			let coords = data.coords// || this.getCoords(curvedText);
			curvedText.set(coords);	
			curvedText.set('type', 'text-curved');		
			curvedText.on('selected', function () {
				self.emitter.emit('textSelected');
			});
				
			curvedText.canvas = isProxy(curvedText.canvas) ? toRaw(curvedText.canvas) : curvedText.canvas;
			canvas.setActiveObject(curvedText);

			curvedText.setCoords();
			if (setActive) {
				canvas.centerObjectH(curvedText);
				canvas.centerObjectV(curvedText);
				curvedText.set('calculatePositionX', (curvedText.left / canvas.getWidth()) * 100);
				curvedText.set('calculatePositionY', (curvedText.top / canvas.getHeight()) * 100);
			} else {
				curvedText.set('left', (curvedText.left + 10) * Math.pow(this.factor, zoom));
				curvedText.set('top', (curvedText.top + 10) * Math.pow(this.factor, zoom));
				// curvedText.set('calculatePositionX', curvedText.calculatePositionX);
				// curvedText.set('calculatePositionY', curvedText.calculatePositionY);
			}
			curvedText.set('diametr', curvedText.diametr || 360);
			// curvedText.scaleX = 1;
			// curvedText.scaleY = 1;
			
			if (!isDuplicate) {
				// curvedText.scaleX *= Math.pow(this.factor, zoom);
				// curvedText.scaleY *= Math.pow(this.factor, zoom);
				curvedText.diametr = curvedText.diametr * Math.pow(this.factor, zoom);
			}
			canvas.add(curvedText);

			if (setActive) {
				this.canvasUpdated(updateHistory, side);
			}
			canvas.renderAll();
			this.showActionBlock(true);
			this.$refs.cart.setTotalPrice();
			this.setToTopCuttingLine();
		},
		addBorder(data, activeSide, isCreate = true, newShape = false) {
			let side = activeSide ? activeSide : this.$store.state.canvas.$side;
			let canvas = side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let hasBorder = null;
			let objects = canvas.getObjects();
			hasBorder = objects.find((item) => item.type == 'border');
			if (!hasBorder) {
				data = data || {};
				let self = this;

				let border = fabric.util.object.clone(canvas.backgroundImage);
				let updateHistory = typeof data.updateHistory == 'undefined' ? true : data.updateHistory;
				let borderData = {
					type: 'border',
					perPixelTargetFind: true,
					fill: 'transparent',
					lockMovementX: true,
					lockMovementY: true,
					lockScalingX: true,
					lockScalingY: true,
					hasRotatingPoint: false,
					transparentCorners: false
				};
				borderData = {...this.defaults.Border, ...borderData};
				if (isCreate) {
					Object.defineProperty(borderData, 'order', {
						value: new Date().getTime(),
						writable: true,
						enumerable: true,
						configurable: true
					});
					this.canvasUpdated(updateHistory, side);
					
				}
				border.set(borderData);
		
				if (newShape) {
					border.set('scaleX', 1 * Math.pow(self.factor, self.zoom[side]));
					border.set('scaleY', 1 * Math.pow(self.factor, self.zoom[side]));
					border.set('strokeWidth', 10 * Math.pow(self.factor, self.zoom[side]));
				} else {
					border.set('scaleX', (data.scaleX || this.defaults.Border.scaleX) * Math.pow(self.factor, self.zoom[side]));
					border.set('scaleY', (data.scaleY || this.defaults.Border.scaleY) * Math.pow(self.factor, self.zoom[side]));
					border.set('strokeWidth', (data.strokeWidth || this.defaults.Border.strokeWidth) * Math.pow(self.factor, self.zoom[side]));
				}
				
				border.set('stroke', data.stroke || borderData.stroke);
				border.set('type', 'border');
			
				border.on('selected', function () {
					self.$refs.controlpanel.borderSelected();
				});
				canvas.setActiveObject(border);
				if (typeof data.returnObject != 'undefined' && data.returnObject === true) {
					return border;
				}
				canvas.centerObjectH(border);
				canvas.centerObjectV(border);
				canvas.add(border);

				canvas.renderAll();
				this.showActionBlock(true);
				this.$refs.cart.setTotalPrice();
				this.setToTopCuttingLine();
			}
			return false;
		},
		addImage(data, settedSide, isCreate = true, isDuplicate = false, isDraggable = false, isReturn) {
			this.data.$rendering++;
			let updateHistory = typeof data.updateHistory == 'undefined' ? true : data.updateHistory;
			let self = this;
			let side = settedSide ? settedSide : self.$store.state.canvas.$side;
			let canvas = side == 'front' ? self.data.$canvasFront : self.data.$canvasBack;
			fabric.Image.fromURL(data.src, function (img) {
				let imgData = {
					globalCompositeOperation: 'source-atop'
				};
				img.width = canvas.getWidth();
				img.height = canvas.getHeight();
				Object.defineProperty(imgData, 'order', {
					value: new Date().getTime(),
					writable: true,
					enumerable: true,
					configurable: true
				});

				Object.assign(imgData, data);

				let zoom = Math.pow(self.factor, self.zoom[side]);

				img.set(imgData); 
			
				if (isCreate && !isReturn) {
					img.scaleToWidth((canvas.getWidth() / 2), false);
					// img.scaleToHeight((canvas.getHeight() / 2) - 20, false);

					canvas.centerObjectH(img);
					canvas.centerObjectV(img);
					img.set('calculatePositionX', (img.left / canvas.getWidth()) * 100);
					img.set('calculatePositionY', (img.top / canvas.getHeight()) * 100);
				} else {
					img.set('left', (img.left + 10) * zoom);
					img.set('top',  (img.top + 10) * zoom);

					img.set('scaleX', img.scaleX * zoom);
					img.set('scaleY', img.scaleY * zoom);

					// img.set('calculatePositionX', img.calculatePositionX);
					// img.set('calculatePositionY', img.calculatePositionY);
				}
				
				img.set('crossOrigin', '*'); 
				img.set('type', 'image');
				img.set('lockMovementY', data.lockMovementY); 
				img.set('lockMovementX', data.lockMovementX); 
				img.set('lockScalingX', data.lockScalingX); 
				img.set('lockScalingY', data.lockScalingY);

				img.setCoords();
				canvas.add(img);
				img.on('selected', function () {
					self.emitter.emit('imageSelected');
				});
				canvas.setActiveObject(img);
				if (isCreate) {
					self.canvasUpdated(updateHistory, side, isDraggable);
				}
				canvas.renderAll();
				if ('layer' in data) {
					canvas.moveTo(img, data.layer);
				}
				if (isDuplicate) {
					canvas.bringToFront(img);
				}
				self.setToTopCuttingLine();
				self.$refs.layer.renderLayers();
				self.$refs.cart.setTotalPrice();
				self.showActionBlock(true);
			},{crossOrigin: "Anonymous"});
		},
		setShapeColor(color) {
			this.default.fill = color;
		},
		showGraphics() {
			this.$nextTick(()=>{
				this.$refs.graphics.openGraphics();
			});
		},
		showImage() {
			this.$nextTick(()=>{
				this.$refs.popupImage.openPopUp();
			});
		},
		showUploadImages() {
			this.$nextTick(()=>{
				this.$refs.uploadsImage.openUploadImage();
			});
		},
		// showImagesGallery() {
		// 	this.$nextTick(()=>{
		// 		this.$refs.browserGallery.openImagesGallery();
		// 	});			
		// },
		cloneLayer(data) {
			let side = this.$store.state.canvas.$side;
			let setActive = true;
			let callback = '';
			
			var object = Object.create(data['layer']);
			for (let i in data['layer']) {
				object.set(i, data['layer'][i]);
			}
			object.set('order', new Date().getTime());
			
			let type = object.type;
			if (object) {						
				if (type == 'i-text' || type == 'text-curved' || type == 'image') {
					object.top += 40;
					switch (type) {
						case 'i-text':
							callback = 'addIText';
							break;
						case 'text-curved':
							callback = 'addCurvedText';
							break;
						case 'image':
							callback = 'addImage';
							break;
					}
					object['updateHistory'] = false;
					this[callback](object, side, setActive, true);
				}
			}
		},
		showNameLabelForSave() {

			this.$bvModal.show('save-labels');
		},
		showNameProductForSave() {
			this.$bvModal.show('save-product');
		},
		increaseCanvas(sideCanvas) {
			let side = sideCanvas ? sideCanvas : this.$store.state.canvas.$side;
			if (+this.zoom[side] <= 23) {
				let canvas = side == 'front' ? this.$canvasFront : this.$canvasBack
				let factor = this.factor;
				
				canvas.setHeight(canvas.getHeight() * factor);
				canvas.setWidth(canvas.getWidth() * factor);
				var bi = null;
				if (canvas.backgroundImage) {
					bi = canvas.backgroundImage;
					bi.scaleX = bi.scaleX * factor;
					bi.scaleY = bi.scaleY * factor;

					bi.top = bi.top * factor;
					bi.left = bi.left * factor;
				}

				var objects = canvas.getObjects();
				for (var i in toRaw(objects)) {
					objects[i].scaleX = objects[i].scaleX * factor;
					objects[i].scaleY = objects[i].scaleY * factor;

					objects[i].left = objects[i].left * factor;
					objects[i].top = objects[i].top * factor;

					if(objects[i].id == 'cuttingLine' || objects[i].id == 'cuttingLineDashed') {
						objects[i].strokeWidth = 0.5;
					} else {
						objects[i].strokeWidth =  objects[i].strokeWidth * factor;
					}
					if(objects[i].type == 'text-curved') {
						// objects[i].fontSize = objects[i].fontSize * factor;
					}
				}
				canvas.renderAll();
				canvas.calcOffset();
		
				this.zoom[side] += 1;		
			}
		},
		increaseBackground() {
			let canvas = this.canvases[this.$store.state.canvas.$side].canvas;
			let factor = this.factor;
			
			canvas.setHeight(canvas.getHeight() * factor);
			canvas.setWidth(canvas.getWidth() * factor);
			var bi = null;
			if (canvas.backgroundImage) {
				bi = canvas.backgroundImage;
				bi.scaleX = bi.scaleX * factor;
				bi.scaleY = bi.scaleY * factor;

				bi.top = bi.top * factor;
				bi.left = bi.left * factor;
			}
			
			var objects = canvas.getObjects();
			for (var i in toRaw(objects)) {
				if(objects[i].id == 'cuttingLine' || objects[i].id == 'cuttingLineDashed') {
					objects[i].scaleX = objects[i].scaleX * factor;
					objects[i].scaleY = objects[i].scaleY * factor;
					objects[i].left = objects[i].left * factor;
					objects[i].top = objects[i].top * factor;
					objects[i].strokeWidth = 0.5;					
				} 
			}
			canvas.renderAll();
			canvas.calcOffset();
	
			this.zoom += 1;
		},
		decreaseCanvas(sideCanvas) {
			let side = sideCanvas ? sideCanvas : this.$store.state.canvas.$side;
			if (+this.zoom[side] >= -12) {
				let canvas = side == 'front' ? this.$canvasFront : this.$canvasBack// this.canvases[side].canvas;
				let	factor = this.factor;
		
				canvas.setHeight(canvas.getHeight() / factor);
				canvas.setWidth(canvas.getWidth() / factor);

				var bi = null;
				if (canvas.backgroundImage) {
					bi = canvas.backgroundImage;
					bi.top = bi.top / factor;
					bi.left = bi.left / factor;

					bi.scaleX = bi.scaleX / factor;
					bi.scaleY = bi.scaleY / factor;
				}
				var objects = canvas.getObjects();
				for (var i in objects) {					
					objects[i].scaleX = objects[i].scaleX / factor;
					objects[i].scaleY = objects[i].scaleY / factor;

					objects[i].left = objects[i].left / factor;
					objects[i].top = objects[i].top / factor;
						
					if(objects[i].id == 'cuttingLine' || objects[i].id == 'cuttingLineDashed') {
						objects[i].strokeWidth = 0.5;
					} else {
						objects[i].strokeWidth =  objects[i].strokeWidth / factor;
					}
					if(objects[i].type == 'text-curved') {
						// objects[i].fontSize = objects[i].fontSize / factor;
					}
					objects[i].setCoords();
				}
				canvas.renderAll();
				canvas.calcOffset();
				
				this.zoom[side] -= 1;
			}
		},
		undoCanvas() {
			if (this.history[this.$store.state.canvas.$side].current.length == 0) {
				 return false;
			}
			let currentChange = this.history[this.$store.state.canvas.$side].current.shift();
			this.history[this.$store.state.canvas.$side].trash.push(currentChange);

			this.renderHistory(true);
		},
		redoCanvas() {
			if (this.history[this.$store.state.canvas.$side].trash.length == 0) {
				return false;
			}
			let trash = this.history[this.$store.state.canvas.$side].trash.pop();
			this.history[this.$store.state.canvas.$side].current.unshift(trash);

			this.renderHistory(true);
		},
		removing(obj, deleteObjects) {
			let objects = obj._objects, index;
			for (var i = 0; i < deleteObjects.length; i++) {
				index = objects.indexOf(deleteObjects[i]);
				if (index > -1 && deleteObjects[i].id != 'cuttingLine' && deleteObjects[i].id != 'cuttingLineDashed') {
					objects.splice(index, 1);
				}
			}
			obj.discardActiveObject().renderAll();
			this.$nextTick(() => {
				this.$refs.layer.renderLayers();
			});
			return obj;
		},
		renderHistory(isReturn) {
			let side = this.$store.state.canvas.$side;
			let activeCanvas = side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let objects = activeCanvas.getObjects();

			// objects.shift();
			activeCanvas = this.removing(activeCanvas, objects);
			
			if (this.history[side].current.length == 0 &&  this.historyColection[side].length == 0) {
				return false;
			} 

			if (this.history[side].current.length) {
				let history = this.history[side].current[0];			
				this.renderObjects(history, side, false, false, false, isReturn);
			}

			if (this.historyColection[side].length && this.history[side].current.length == 0) {
				this.renderObjects(this.historyColection[side], side, false, false, false, isReturn);
			}

			this.$nextTick(() => {
				this.$refs.layer.renderLayers();
			});
				
		},
		updateHistory(data, previousDelete) {
			let self = this;
			let side = (data && (data === 'front' || data === 'back')) ? data : this.$store.state.canvas.$side;
			let canvas = side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let objs = canvas.getObjects().map(function(o) {
				if (o.type != 'path' && o.type != 'rect') {
					return self.getObjectParams(o);
				}
			});
	
			//objs.splice(0, 1);
			let ob = objs.filter((o) => {
				if (o) {
					if (o.type == 'border') {
						return o;
					} else {
						if(o.type =='text-curved'){
							o.scaleX /= Math.pow(this.factor, self.zoom[side]);
							o.scaleY /= Math.pow(this.factor, self.zoom[side]);
						}
						if (o.type == 'image') {
							o.scaleX /= Math.pow(this.factor, self.zoom[side]);
							o.scaleY /= Math.pow(self.factor, self.zoom[side]);
						}
						o.top /= Math.pow(this.factor, self.zoom[side]);
						o.left /= Math.pow(this.factor, self.zoom[side]);
						o.top -= 10;
						o.left -= 10;
						o.zoom = self.zoom[side];

						return o;
					}
				}
			});
			// if (previousDelete) {
			// 	this.history[side].trash.push(this.history[side].current.pop());
			// }
			if (ob && ob.length) {
				this.history[side].current.unshift(ob);
			}
		},
		canvasUpdated(updateHistory, side, previousDelete = false) {
			let canvas = this.data.$canvasFront;

			if ((side && side == 'back') || (!side && this.$store.state.canvas.$side == 'back')) {
				canvas = this.data.$canvasBack;
			}
			if (canvas) {
				if (updateHistory === true) {
					this.updateHistory(this.$store.state.canvas.$side, previousDelete);
				}
				this.$nextTick(() => {
					this.$refs.controlpanel.setSizeParamsForShape();
				});
				//canvas.renderAll();
				this.$nextTick(() => {
					this.$refs.layer.renderLayers();
				});
				this.data.$rendering--;
			}
		},
		removeBackLayers() {
			let objectsB = this.data.$canvasBack.getObjects();
			let layers = [];			 
			for (let i in objectsB) {
				if (objectsB[i].id != 'cuttingLine' && objectsB[i].id != 'cuttingLineDashed') {	
					layers.push(objectsB[i]);
				}				
			}
			this.data.$canvasBack.remove(...layers);
			this.history['back'].current = [];
			this.history['back'].trash = [];
			this.$refs.cart.setTotalPrice();
		},
		async drugAndDropLayers(data) {
			this.clearCanvases();
			let objs = data.objects.reverse();
			await this.renderObjects(objs, this.$store.state.canvas.$side, false, true, true);
		},
		/**
		 * isCreate: true when only creating the new object, using for auto selected after cteate, and set center
		 * createdBefore: true if object was created, using for duplicate, history for correct zooming
		 */
		renderObjects(objects, side, isCreate, createdBefore, isDraggable = false, isReturn) {
			if (objects) {
				let self = this;
				let currentObject = isProxy(objects) ? toRaw(objects) : objects;
				 currentObject.forEach((object, index) => {
					if (object) {
						let type = object.type;
						if (type == 'i-text' || type == 'text-curved' || type == 'image' || type == 'border') {	
							let callback = '';
							switch (type) {
								case 'i-text':
									callback = 'addIText';
									break;
								case 'text-curved':
									callback = 'addCurvedText';
									break;
								case 'image':									
									callback = 'addImage';
									break;
								case 'border':
									callback = 'addBorder';
									break;
								default:
									//callback = 'add' + type.charAt(0).toUpperCase() + type.slice(1);
									break;
							}
							//object['updateHistory'] = false;
							object['layer'] = index;
							this[callback](object, side, isCreate, createdBefore, isDraggable, isReturn);
						}
						
					}
				});
			}
		},
		startOver() {
			let objectsF = this.data.$canvasFront.getObjects();
			this.data.$canvasFront.remove(...objectsF);
			let objectsB = this.data.$canvasBack.getObjects();
			this.data.$canvasBack.remove(...objectsB);

			this.history['front'].current = [];
			this.history['front'].trash = [];
			this.history['back'].current = [];
			this.history['back'].trash = [];

			let shapeFront = {
				backgrounds_shape: 'Rectangle',
				fill: '#FFFFFF',
				width: 3.3,
				height: 4,
				value: '3.3" × 4"'
			};
			let shapeBack = {
				backgrounds_shape: 'Rectangle',
				fill: '#FFFFFF',
				width: 2,
				height: 2.4,
				value: '2" × 2.4"'
			};
			this.zoom['front'] = 8;	
			this.zoom['back'] = 8;	
			this.setupBgShape(shapeFront, 'front');
			this.setupBgShape(shapeBack, 'back');

			this.$nextTick(() => {
				this.$refs.layer.renderLayers();
			});

			if (this.$route.params.id || (this.$route.params.id && this.$route.params.param == 'personal')) {
				this.getProductBySlug();
			}
			this.$refs.cart.setTotalPrice();
		},
		deleteObjects(selectedObject) {
			this.data.$rendering++;
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			if (selectedObject && selectedObject.layer) {
				activeCanvas.remove(selectedObject.layer);
				activeCanvas.discardActiveObject().renderAll();
				this.canvasUpdated();
				this.$refs.cart.setTotalPrice();
			}
		},
		deselectObjects(event) {
			if (event.path && event.path[0] && event.path[0].tagName.toLowerCase() != 'canvas') {
				let activeCanvas = this.data.$activeCanvas; 
				activeCanvas.discardActiveObject().renderAll();
			}
		},
		setNewPositionForObject() {			
			let canvas = (this.$store.state.canvas.$side == 'front') ? this.$canvasFront : this.$canvasBack;
			let currentObjects = canvas.getObjects();
			for (let i in currentObjects) {
				if (currentObjects[i].type == 'i-text' || currentObjects[i].type == 'text-curved' || currentObjects[i].type == 'image') {
					if (currentObjects[i].calculatePositionX) {
					currentObjects[i].set('left', +((currentObjects[i].calculatePositionX * canvas.getWidth()) / 100).toFixed(6));
					}
					if (currentObjects[i].calculatePositionY) {
					currentObjects[i].set('top', +((currentObjects[i].calculatePositionY * canvas.getHeight()) / 100).toFixed(6));
					}
				}
			}
		},
		selectShape(data) {
			let side = ('side' in data && data.side == 'front') ? 'front' : ('side' in data && data.side == 'back') ? 'back' : this.$store.state.canvas.$side;
			// if('setNewBorder' in data) {
				if (this.zoom[side] > 0) {
					while (this.zoom[side] > 0) {
						this.decreaseCanvas(side);
					}
				} else if (this.zoom[side] < 0) {
					while (this.zoom[side] < 0) {
						this.increaseCanvas(side);
					}
				}
			// }

			let canvas = (side == 'front') ? this.$canvasFront : this.$canvasBack;
	
			this.canvases[side].settedShape = data.shape.backgrounds_shape;
			this.settedShape[side] = {
					backgrounds_shape: data.shape.backgrounds_shape,
					prefixName:  data.shape.width + 'x' + data.shape.height,// data.shape.value,
					fill: data.shape.fill,
					width: data.shape.width,
					height: data.shape.height
			};
		
			if (this.zoom[side] == 0) { 
				this.setupBgShape(this.settedShape[side], side);
				this.$refs.controlpanel.setSizeAfterChanegSide(this.settedShape[side], side);
				if (data.setPosition) {
					this.$nextTick(()=>{
						this.setNewPositionForObject();
					});
				}
				
				if('setNewBorder' in data && data.setNewBorder) {
					setTimeout(() => {
						let objs = canvas.getObjects();
						let tmpObject = null;
						for (let i in objs) {
							tmpObject = Object.create(objs[i]);
							if (objs[i].type == 'border') {
								canvas.remove(objs[i]);
								this.addBorder(tmpObject, side, false, true);
							}
						}
					}, 200);
				}
				let countI = 0;
				let maxHeight = data.shape.height * 96;
				let maxWidth = data.shape.width * 96;
				
				while (maxHeight < 570 && maxWidth < 770) {
					maxHeight *= this.factor;
					maxWidth *= this.factor;
					if (parseFloat(maxHeight) < 570 && parseFloat(maxWidth) < 770) {
						countI++;
					}
				}
			
				for(let i=0; i<countI; i++) {
					this.increaseCanvas(side);
				}
				
				this.$nextTick(()=>{
					this.$refs.layer.renderLayers();
				});
				this.$refs.cart.setTotalPrice();
			}
		},
		layersRender() {
			this.$nextTick(()=>{
				this.$refs.layer.renderLayers();
			});
		},
		async setupBgShape(selectedShape, selectedSide) {
			await this.$nextTick();
			let self = this;
			let side = selectedSide ? selectedSide : this.$store.state.canvas.$side;
			let bgTop = 0;
			let bgLeft = 0;
			let bgBorderWidth = 0
			let canvasLayer = self.canvases[side];

			let prefixName = (selectedShape.width && selectedShape.height) ? selectedShape.width + 'x' + selectedShape.height : self.settedShape[side].width + 'x' + self.settedShape[side].height;  
		
			let currentZoom = Math.pow(this.factor, this.zoom[side]);
			/**Need add 20px becouse we get basic sizes in inches and we need add cutting line */
			let currentEditorWith = ((selectedShape.width || self.settedShape[side].width) * self.inchesToPixels + 20) * currentZoom;
			let currentEditorHeight = ((selectedShape.height || self.settedShape[side].height) * self.inchesToPixels + 20) * currentZoom;

			let cuttingOffset = 10 * currentZoom;

			/**set defaults data for canvas for current shape, need for zoom */
			this.defaults.Canvas.width = currentEditorWith;
			this.defaults.Canvas.height = currentEditorHeight;
			/**end  block*/
			if (side == 'front') {
				this.data.$canvasFront.setWidth(currentEditorWith);
				this.data.$canvasFront.setHeight(currentEditorHeight);
			}
			if (side == 'back') {
				this.data.$canvasBack.setWidth(currentEditorWith);
				this.data.$canvasBack.setHeight(currentEditorHeight);
			}
			let editorWidth = currentEditorWith - bgBorderWidth;
			let editorHeight = currentEditorHeight - bgBorderWidth;

			let colorSet = selectedShape.fill || self.settedShape[side].fill;
			let cuttingTop = 0;
			let cuttingLeft = 0;
			let cuttingRatio = 1;
			let scaleX = currentZoom;// 1;
			let scaleY = currentZoom;//1;

			let backgroundShape = selectedShape.backgrounds_shape || self.settedShape[side].backgrounds_shape;

			self.settedShape[side].fill = colorSet;

			self.settedShape[side].backgrounds_shape = backgroundShape;
			fabric.loadSVGFromURL(`${this.pathUrl}/images/canvas/${prefixName}${backgroundShape}.svg`, function (objects, options) {
				if (self.canvases[side].shape) {
					self.canvases[side].shape = null;
				}

				canvasLayer.shape = fabric.util.groupSVGElements(objects, options);

				/**sizes of shapes are bigger on 20 pixels */
				let shapeWidth =  options.width * currentZoom;
				let shapeHeight = options.height * currentZoom;

				if (!canvasLayer.shape.path) {
					canvasLayer.shape.set({
						fill: colorSet,
						stroke: '#cec',
						strokeWidth: 0,
						top: 0,
						left: 0,
						width: shapeWidth,
						height: shapeHeight
					});
				} else if (canvasLayer.shape.path) {
					canvasLayer.shape.set({
						fill: colorSet,
						stroke: '#cec',
						strokeWidth: 0,
						top: 0,
						left: 0,
						width: options.width,
						height: options.height,
						strokeUniform: true
					});
				}

				if (side == 'front') {
					self.data.$shapeFront = toRaw(self.canvases[side].shape);
				} else {
					self.data.$shapeBack = toRaw(self.canvases[side].shape);
				}

				if (shapeWidth > shapeHeight) {
					canvasLayer.shape.scaleToWidth(editorWidth);
					canvasLayer.scaleRatio = editorWidth / shapeWidth;
					bgTop = (editorHeight - (shapeHeight * canvasLayer.scaleRatio)) / 2;
					scaleX = (editorWidth - cuttingOffset * 2 ) / shapeWidth;
					scaleY = (canvasLayer.shape.getScaledHeight() - cuttingOffset * 2) / canvasLayer.shape.getScaledHeight() * canvasLayer.scaleRatio;
				} else {
					canvasLayer.shape.scaleToHeight(editorHeight);
					canvasLayer.scaleRatio = editorHeight / shapeHeight;
					bgLeft = (editorWidth - (shapeWidth * canvasLayer.scaleRatio)) / 2;
					scaleY = (editorHeight - cuttingOffset * 2) / shapeHeight;
					scaleX = (canvasLayer.shape.getScaledWidth() - cuttingOffset * 2) / canvasLayer.shape.getScaledWidth() * canvasLayer.scaleRatio;
				}
				canvasLayer.canvas.setBackgroundImage(canvasLayer.shape, canvasLayer.canvas.renderAll.bind(canvasLayer.canvas), {
					left: bgLeft,
					top: bgTop
				});
				/**set cutting line */
				let cuttingLines = canvasLayer.canvas.getObjects();
				if (cuttingLines && cuttingLines.length) {
					for (let i in cuttingLines) {
						if (cuttingLines[i].id && (cuttingLines[i].id == 'cuttingLine' || cuttingLines[i].id == 'cuttingLineDashed')) {							
							canvasLayer.canvas.remove(cuttingLines[i]);
						}
					}
				}
				
				canvasLayer.canvas.remove(canvasLayer.cuttingLine);
				canvasLayer.cuttingLine = fabric.util.object.clone(canvasLayer.shape);
		
				cuttingTop = bgTop + cuttingOffset;
				cuttingLeft = bgLeft + cuttingOffset;
				let geometricShapes = ['rectsa'];
				let isGeometric = geometricShapes.includes(objects[0].id);
				let cuttingLineParams = {
					id: 'cuttingLine',
					top: cuttingTop,
					left: cuttingLeft,
					selectable: false,
					strokeWidth: 0.5,
					evented: false,
					scaleX: scaleX * (isGeometric ? 1 : currentZoom),
					scaleY: scaleY * (isGeometric ? 1 : currentZoom),
					fill: 'rgba(0,0,0, 0)',
					order: new Date().getTime(),
					stroke: '#FFF'
				};
				canvasLayer.cuttingLine.set(cuttingLineParams);
				
				canvasLayer.canvas.remove(canvasLayer.cuttingLineDashed);							
				canvasLayer.cuttingLineDashed = fabric.util.object.clone(canvasLayer.shape);

				let cuttingLineParamsDashed = {//TODO
					id: 'cuttingLineDashed' ,
					top: cuttingTop,
					left: cuttingLeft,
					selectable: false,
					strokeWidth: 0.5,
					evented: false,
					scaleX: scaleX * (isGeometric ? 1 : currentZoom),
					scaleY: scaleY * (isGeometric ? 1 : currentZoom),
					fill: 'rgba(0,0,0, 0)',
					order: new Date().getTime() + 1,
					stroke: '#000',
					strokeDashArray: [5, 5]
				};

				canvasLayer.cuttingLineDashed.set(cuttingLineParamsDashed);

				if (side == 'front') {
					self.data.$cuttingLineFront = canvasLayer.cuttingLine;
					self.data.$cuttingLineDashedFront = canvasLayer.cuttingLineDashed;
				}
				if (side == 'back') {
					self.data.$cuttingLineBack = canvasLayer.cuttingLine;
					self.data.$cuttingLineDashedBack = canvasLayer.cuttingLineDashed;
				}
				
				canvasLayer.canvas.add(canvasLayer.cuttingLine);
				canvasLayer.canvas.add(canvasLayer.cuttingLineDashed);

				let objs = canvasLayer.canvas.getObjects();
				for(let i in objs) {
					if(objs[i].id) {
						if (objs[i].id == 'cuttingLine') {
							canvasLayer.canvas.bringToFront(objs[i]);
						}
					}
				}
				for(let i in objs) {
					if(objs[i].id) {
						if (objs[i].id == 'cuttingLineDashed') {
							canvasLayer.canvas.bringToFront(objs[i]);
						}
					}
				}
				canvasLayer.canvas.selection = false;
				canvasLayer.canvas.renderAll();
			});
		},
		invertColor(hexTripletColor) {
			let activeCanvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			let objects = activeCanvas.getObjects();
			let settedColor = hexTripletColor ? hexTripletColor : null;
			objects.forEach((object) => {
				if (object && object.type == 'border') {
					settedColor = object.stroke;
				}
			})
			if (settedColor.indexOf('#') == 0) {
				let color = hexTripletColor;
				color = color.substring(1);
				color = parseInt(color, 16);
				color = 0xFFFFFF ^ color;
				color = color.toString(16);
				color = ("000000" + color).slice(-6);
				color = "#" + color;

				return color;
			}

			return false;			
		},
		showActionBlock(param = true) {
			let canvas = this.$store.state.canvas.$side == 'front' ? this.data.$canvasFront : this.data.$canvasBack;
			fabric.Canvas.prototype.getAbsoluteCoords = function(object) {
				return {
					left: object.aCoords.tr.x + this._offset.left + 10,
					top: object.top + this._offset.top
				};
			};
			let currentActiveObject = canvas.getActiveObject()
			if (currentActiveObject && currentActiveObject.type) {
				var absCoords = canvas.getAbsoluteCoords(currentActiveObject);

				let trX = (absCoords.left) + 'px';
				let trY = (absCoords.top) + 'px';
				this.choosedLabel.left = trX;
				this.choosedLabel.top = trY;

				this.choosedLabel.show = param ? 'block' : 'none';
				this.choosedLabel.is_text = currentActiveObject.type == 'i-text' || currentActiveObject.type == 'text-curved';
			} else {
				this.choosedLabel.show = 'none';
			}
		},
		setupMouseEvents() {
			let self = this; 
			let canvases = self.canvases;

			for(let side in canvases) {
				canvases[side].canvas.on('mouse:over', function(e) {
					if (!e.target) {
						self.$refs.layer.setActiveMenu(0);
						return false;
					}
				});
				// canvases[side].canvas.on('mouse:down', function(e) {
				// 	console.log(e.target)
				// 	if (e.target.type == 'i-text') { // Перевірка на подвійний клік
				// 		e.target.enterEditing(); // Вмикаємо режим редагування
				// 		e.target.selectAll(); // Виділяємо весь текст
				// 	}
				// });
				canvases[side].canvas.on('mouse:down', function(e) {
					if (!e.target) {
						self.$refs.layer.setActiveMenu(0);
						return false;
					}
					if (e.target && e.target.order) {
						self.$refs.layer.setActiveMenu(e.target.order);
					}
					// self.history[side].last = self.getObjectParams(e.target);
					self.showActionBlock(false);
					document.body.style.overflow = 'hidden';
					document.body.style.paddingRight = '17px';
					setTimeout(function() {
						document.body.style.overflow = 'auto';
						document.body.style.paddingRight = '0px';
					}, 0);
					self.$nextTick(() => {
						//self.$refs.layer.renderLayers();
					});	
					if (e.target && e.target.type === 'i-text' && e.e.detail === 2) {
						e.target.enterEditing(); // Вмикаємо режим редагування
						e.target.selectAll(); // Виділяємо весь текст
					}		
				});//after:render object:modified
				canvases[side].canvas.on('object:moving', function(e) {
					if (!e.target) {
						return false;
					}
					self.object.moved = true;
					let coords = self.getCoords(e.target);
					self.showActionBlock(false);

					if (coords !== null) {
						e.target.set(coords);
					}
				});

				canvases[side].canvas.on('object:scaling', function(e) {	
					if (!e.target) {
						return false;
					}
					self.showActionBlock(false);
				});
				canvases[side].canvas.on('mouse:up', function(e) {
					if (!e.target) {
						return false;
					}
					
					self.showActionBlock(true);
					if (self.object.moved) {
						self.object.moved = false;
					}
					let coords = self.getCoords(e.target);
					if (coords !== null) {
						e.target.set(coords);
					}
					let params = self.getObjectParams(e.target);
					if (params && e.target.type != 'i-text') {
						canvases[side].canvas.setActiveObject(e.target);
						self.updateHistory(true);
						canvases[side].canvas.renderAll();
					}
					e.target.set('calculatePositionX', (e.target.left / canvases[side].canvas.getWidth()) * 100);
					e.target.set('calculatePositionY', (e.target.top / canvases[side].canvas.getHeight()) * 100);
				});
				canvases[side].canvas.on('object:modified', function(e) {
					if (!e.target) {
						return false;
					}
					self.showActionBlock(false);
					//self.updateHistory(true);
				});
				canvases[side].canvas.on('before:selection:cleared', function(e) {
					if (!e.target) {
						return false;
					}
					self.emitter.emit('selectionCleared');
					self.choosedLabel.show = 'none';
					self.choosedLabel.is_text = false;
				});
			}
		},
		// layerUpAction() {
		// 	this.$nextTick(()=>{
		// 		this.$refs.layer.layerUp();
		// 	});
		// },
		// layerDownAction() {
		// 	this.$nextTick(()=>{
		// 		this.$refs.layer.layerDown();
		// 	});
		// },
		renderCanvas(canvas, editor) {
			canvas.getObjects().forEach(o => {
				if (o.type === 'path') {
					return true;
				}
				let top = parseInt(editor.clientHeight * (o.topPercent / 100));
				let left = parseInt(editor.clientWidth * (o.leftPercent / 100));
				let ratio = top / o.top;
				o.set({
					top: o.type === 'border' ? 0 : top,
					left: o.type === 'border' ? 0 : left
				});

				if (o.type === 'image') {
					o.set({
						scaleX: o.scaleX * ratio,
						scaleY: o.scaleY * ratio
					});
				} else if(o.type === 'border') {
					o.set({
						width: canvas.width-20,
						height: canvas.height-20
					});
				} else {
					o.set({
						width: o.width * ratio,
						height: o.height * ratio
					});
				}
			});
			canvas.renderAll();
		},
		// setMaterialType(data) {
		// 	this.settedShape.material_type = data.type;
		// },
		selectedEditor(side) {
			this.setSide(side);
			this.choosedLabel.show = 'none';
		},
		setActiveBackSide(status) {
			this.setShowBackCanvas(status);
		},
		updateOrderData() {
			let tmp_email = localStorage.getItem('tmp_email');
			if (tmp_email && this.$store.state.profileModule.userEmail) {
				let form = new FormData;
				form.append('customer_email', this.$store.state.profileModule.userEmail);
				form.append('tmp_email', tmp_email);
				form.append('action' , 'dm_set_order_email');
				axios({
					method: 'post',
					url: this.$wpAjaxUrl,
					data: form
				}).then(response => {
					if (response && response.data && response.data.status == 'OK') {
						localStorage.removeItem('tmp_email');
					}
				}).catch(error => {
					console.log(error);
				});
			}
		},
		changeDpiDataUrl(dataUrl, dpi) {
			let parts = dataUrl.split(',');
			let header = parts[0];
			let data = parts[1];
			let base64Str = atob(data);
			
			let uint8Array = new Uint8Array(base64Str.length);
			for (let i = 0; i < base64Str.length; i++) {
				uint8Array[i] = base64Str.charCodeAt(i);
			}

			let pngChunkText = function (text) {
				let chunk = new Uint8Array(text.length);
				for (let i = 0; i < text.length; i++) {
					chunk[i] = text.charCodeAt(i);
				}
				return chunk;
			};

			let DPI = pngChunkText("pHYs");
			let ppi = Math.round(dpi / 0.0254); // pixels per meter
			let dpiArray = new Uint8Array([
				(ppi >> 24) & 0xff, (ppi >> 16) & 0xff, (ppi >> 8) & 0xff, ppi & 0xff,
				(ppi >> 24) & 0xff, (ppi >> 16) & 0xff, (ppi >> 8) & 0xff, ppi & 0xff, 
				1 // unit specifier (1 for meters)
			]);

			let chunks = [
				uint8Array.subarray(0, 33), // PNG header and IHDR chunk
				DPI, 
				dpiArray,
				uint8Array.subarray(33) // rest of the PNG file
			];

			let outputArray = new Uint8Array(chunks.reduce((acc, chunk) => acc.concat(Array.from(chunk)), []));
			return header + "," + btoa(String.fromCharCode.apply(null, outputArray));
		},
		addToCart() {
			/**
			 * Before need to check front label and back if it's included into cart (green button on the back label)
			 */
			if ((this.$canvasFront.getObjects().length == 2 && !this.statusIncludeBackLabel) ||
				(this.$canvasFront.getObjects().length == 2 && this.statusIncludeBackLabel && this.$canvasBack.getObjects().length == 2)) {
				this.$refs.cart.emptyShape();
				return false;
			}
			this.loading = true;
			let form = new FormData;
			let history = {
				front: {},
				back: {}
			};	
			/**
			 * Set zoom to 0
			 */		
			if (this.zoom['front'] > 0) {
				for (let j=this.zoom['front']; j>0; j--) {
					this.decreaseCanvas('front');
				}
			}
			if (this.zoom['front'] < 0) {
				for (let i=this.zoom['front']; i<0; i++) {
					this.increaseCanvas('front');
				}
			}
			/**
			 * Do it if the back label included to the order
			 */
		
			if (this.statusIncludeBackLabel) {
				if (this.zoom['back'] > 0) {
					for (let j=this.zoom['back']; j>0; j--) {
						this.decreaseCanvas('back');			
					}
				}	
				if (this.zoom['back'] < 0) {
					for (let i=this.zoom['back']; i<0; i++) {
						this.increaseCanvas('back');
					}
				}
			}
			
			let self = this;
			/**
			 * Set size for canvas
			 */
			// this.$canvasFront.setWidth(this.$store.state.order.productSizes['front'].width * this.inchesToPixels);
			// this.$canvasFront.setHeight(this.$store.state.order.productSizes['front'].height * this.inchesToPixels);
			/**
			 * Do it if the back label included to the order
			 */
			if (this.statusIncludeBackLabel) {
				this.$canvasBack.setWidth(this.$store.state.order.productSizes['back'].width * this.inchesToPixels);
				this.$canvasBack.setHeight(this.$store.state.order.productSizes['back'].height * this.inchesToPixels);
			}
			/**
			 * Get parameters for background image
			 */
			let backgrounds = {
					front:{
						backgrounds_shape: self.settedShape['front'].backgrounds_shape,
						fill: self.settedShape['front'].fill,
						height: self.$store.state.order.productSizes['front'].height,
						width: self.$store.state.order.productSizes['front'].width
				},
					/**
					 * Set back parameters only if back label included
					 */
					back: {
						backgrounds_shape: this.statusIncludeBackLabel ? self.settedShape['back'].backgrounds_shape : null,
						fill: this.statusIncludeBackLabel ? self.settedShape['back'].fill : null,
						height: this.statusIncludeBackLabel ? self.$store.state.order.productSizes['back'].height : 0,
						width: this.statusIncludeBackLabel ? self.$store.state.order.productSizes['back'].width : 0
					}
				};
			/**
			 * Set background with sizes without 20px
			 */
			fabric.loadSVGFromURL(`${this.pathUrl}/images/basic/${backgrounds['front'].width}x${backgrounds['front'].height}${backgrounds['front'].backgrounds_shape}.svg`, function(objects, options) {
				let obj = fabric.util.groupSVGElements(objects, options);		
				/**
				 * Set size for canvas
				 */
				self.$canvasFront.setWidth(self.$store.state.order.productSizes['front'].width * self.inchesToPixels);
				self.$canvasFront.setHeight(self.$store.state.order.productSizes['front'].height * self.inchesToPixels);

				self.$canvasFront.backgroundImage.d = obj.d;
				self.$canvasFront.backgroundImage.path = obj.path;
				self.$canvasFront.backgroundImage.pathOffset = obj.pathOffset;
				self.$canvasFront.backgroundImage.width = self.$canvasFront.width;
				self.$canvasFront.backgroundImage.height = self.$canvasFront.height;
			});
			/**
			 * Changing the background shape sizes only if back label included into the order
			 */
			if (this.statusIncludeBackLabel) {
				fabric.loadSVGFromURL(`${this.pathUrl}/images/basic/${backgrounds['back'].width}x${backgrounds['back'].height}${backgrounds['back'].backgrounds_shape}.svg`, function (objects, options) {
					let objBack = fabric.util.groupSVGElements(objects, options);
					/**
					 * Set size for canvas
					 */
					self.$canvasBack.setWidth(self.$store.state.order.productSizes['back'].width * self.inchesToPixels);
					self.$canvasBack.setHeight(self.$store.state.order.productSizes['back'].height * self.inchesToPixels);

					self.$canvasBack.backgroundImage.d = objBack.d;
					self.$canvasBack.backgroundImage.path = objBack.path;
					self.$canvasBack.backgroundImage.pathOffset = objBack.pathOffset;
					self.$canvasBack.backgroundImage.width = self.$canvasBack.width;
					self.$canvasBack.backgroundImage.height = self.$canvasBack.height;
				});
			}	
			
			/**
			 * TODO does not work correct if it does not have timeout
			 */		
		
				let objectsFront = self.$canvasFront.getObjects();

				for (var i in objectsFront) {
					objectsFront[i].set('left', objectsFront[i].left - 10);
					objectsFront[i].set('top', objectsFront[i].top - 10);
					if (objectsFront[i].type == 'i-text' || objectsFront[i].type == 'text-curved' || objectsFront[i].type == 'image') {
						objectsFront[i].set('calculatePositionX', (+objectsFront[i].left / +self.$canvasFront.getWidth()) * 100);
						objectsFront[i].set('calculatePositionY', (+objectsFront[i].top / +self.$canvasFront.getHeight()) * 100);
					}
					objectsFront[i].setCoords();
					if (objectsFront[i].id && (objectsFront[i].id == 'cuttingLine' || objectsFront[i].id == 'cuttingLineDashed')) {
						self.$canvasFront.remove(objectsFront[i]);
					}
				}
				/**
				 * Do it if the back label included to the order
				 */
				let objectsBack = self.$canvasBack.getObjects();
				for (var i in objectsBack) {
					objectsBack[i].set('left', objectsBack[i].left - 10);
					objectsBack[i].set('top', objectsBack[i].top - 10);
					if (objectsBack[i].type == 'i-text' || objectsBack[i].type == 'text-curved' || objectsBack[i].type == 'image') {
						objectsBack[i].set('calculatePositionX', (+objectsBack[i].left / +self.$canvasBack.getWidth()) * 100);
						objectsBack[i].set('calculatePositionY', (+objectsBack[i].top / +self.$canvasBack.getHeight()) * 100);
					}
					objectsBack[i].setCoords();
					if (objectsBack[i].id && (objectsBack[i].id == 'cuttingLine' || objectsBack[i].id == 'cuttingLineDashed')) {
						self.$canvasBack.remove(objectsBack[i]);
					}
				}
				

				/**
				 * Send back data only if its included to the order
				 */
				const productSizes = {
					front: {
						width: this.$store.state.order.productSizes.front.width,
						height: this.$store.state.order.productSizes.front.height
					},
					back: {
						width: this.statusIncludeBackLabel ? this.$store.state.order.productSizes.back.width : 0,
						height: this.statusIncludeBackLabel ? this.$store.state.order.productSizes.back.height : 0
					}
				};
	
			setTimeout(() => {
				for (let i=0; i<=23; i++) {
					this.increaseCanvas('front');
				}
				for (let i=0; i<=23; i++) {
					this.increaseCanvas('back');
				}
				/**
				 * The list of the custom fields which we need to save
				 */
				 history['front'] = self.$canvasFront.toJSON(['charSpacing', 'order', 'isCustom', 'isUploaded', 'calculatePositionX', 'calculatePositionY', 'shadow', 'scaleX', 'scaleY', 'lockMovementX', 'lockMovementY', 'lockRotation', 'lockScalingX', 'lockScalingY']);
				/**
				 * Do it if the back label included to the order
				 */
				if (this.statusIncludeBackLabel) {
					history['back'] = self.$canvasBack.toJSON(['charSpacing', 'order', 'isCustom', 'isUploaded', 'calculatePositionX', 'calculatePositionY', 'shadow', 'scaleX', 'scaleY', 'lockMovementX', 'lockMovementY', 'lockRotation', 'lockScalingX', 'lockScalingY']);
				}
				form.append('action', 'dm_create_order');
				form.append('preset', JSON.stringify(this.$store.state.order.productPreset));
				form.append('size', JSON.stringify(productSizes));
				form.append('count', this.$store.state.order.productCount);
				form.append('price', JSON.stringify(this.$store.state.order.productPrice));
				form.append('product_slug', this.$store.state.order.productSlug || 'custom-label');
				form.append('comments', '');
				form.append('product_name', this.$store.state.order.productName || 'Custom product');

				form.append('canvas_front', JSON.stringify(history['front']));
				form.append('canvas_back', this.statusIncludeBackLabel ? JSON.stringify(history['back']) : JSON.stringify({}));
			
				this.updateOrderData();

				let tmp_email = localStorage.getItem('email') || localStorage.getItem('tmp_email');
				let customerEmail = this.$store.state.profileModule.userEmail;
				let email = localStorage.getItem('email')
				if (!customerEmail) {
					email = email || tmp_email || Math.random().toString(16).slice(2) + '_guest@mail.com';
					localStorage.setItem('tmp_email', email);
					customerEmail = email;
				}

				form.append('customer_email', customerEmail);
				form.append('backgrounds_shape', JSON.stringify(backgrounds));

				let dataUrl = self.$canvasFront.toDataURL({format: 'png',quality: 1}); // згенерувати стандартний dataURL
				let highDpiDataUrl = changeDpiDataUrl(dataUrl, 300); // змінити DPI до 300

				form.append('image_front', highDpiDataUrl);
				if (this.statusIncludeBackLabel) {
					let dataUrlBack = self.$canvasBack.toDataURL('image/png', 1);
					let highDpiDataUrlBack = changeDpiDataUrl(dataUrlBack, 300);
					form.append('image_back', highDpiDataUrlBack);
				} else {
					form.append('image_back', JSON.stringify({}));
				}

				// form.append('image_front', JSON.stringify(self.$canvasFront.toDataURL({
				// 						format: 'png',
				// 						quality: 1,
				// 					})));
				// form.append('image_back', this.statusIncludeBackLabel ? JSON.stringify(self.$canvasBack.toDataURL({
				// 						format: 'png',
				// 						quality: 1,
				// 					})) : JSON.stringify({}));
				let productImages = {
					front: self.$canvasFront.toDataURL({
										format: 'png',
										quality: 1,
									}),
					back: this.statusIncludeBackLabel ? self.$canvasBack.toDataURL({
										format: 'png',
										quality: 1,
									}) : null
				}
				this.setProductImages(productImages);
				let canvasFrontSVG = '';
				let canvasBackSVG = '';
				
				// try{
				// 	canvasFrontSVG = self.$canvasFront.toSVG();
				// }catch(e){
				// 	console.log(e)
				// }
		
				// try{
				// 	canvasBackSVG = this.statusIncludeBackLabel ? self.$canvasBack.toSVG() : '';
				// }catch(e){
				// 	console.log(e)
				// }
				
				form.append('product_svg_front', canvasFrontSVG);
				form.append('product_svg_back', canvasBackSVG);			
				axios({
					method: 'post',
					url: this.$wpAjaxUrl,
					data: form
				}).then((response) => {
					if (response.data.status == 'OK') {
						this.history['front'].current = [];
						this.history['back'].current = [];
						this.$router.push('/shoping-cart');
					}
				}).catch(error => {
					console.log(error);
				});
				//this.emitter.all.clear();
			}, 600);
		},
		clearCanvases(side) {//TODO
			let canvases = [
				this.data.$canvasFront,
				this.data.$canvasBack
			];
			let objects = null;
			if (side) {
				objects = (side && side == 'front') ? this.data.$canvasFront : this.data.$canvasBack;
				let objs = objects.getObjects();
				for (let j in objs) {
					if (objs[j].id && (objs[j].id == 'cuttingLine' || objs[j].id == 'cuttingLineDashed')) {
						continue;
					}
					if (objs[j] && objs[j] != undefined) {
						objects.remove(objs[j]);
					}
				}
			} else {
				for (let i in canvases) {
					objects = canvases[i].getObjects();
					for (let j in objects) {
						if (objects[j].id && (objects[j].id == 'cuttingLine' || objects[j].id == 'cuttingLineDashed')) {
							continue;
						}
						if (objects[j] && objects[j] != undefined) {
							canvases[i].remove(objects[j]);
						}
					}
				}
			}
		},
		selectUserLabel(data) {
			this.clearCanvases();//TODO need clear before edit label
			this.settedShape.product_params.name = '';
			this.settedShape.product_params.product_id = 0;

			this.setupBgShape(data.backgrounds_shape['front'], 'front');
			this.renderObjects(data.label['front'], 'front');

			this.setupBgShape(data.backgrounds_shape['back'], 'back');
			this.renderObjects(data.label['back'], 'back');

			this.settedShape.product_params.name = data.name;
			this.settedShape.product_params.product_id = data.id;
			this.setProductStatus(data.is_product);
			this.setProductName(data.name);

			let paramsSize = {
				front: {
					width: data.backgrounds_shape['front'].backgrounds_shape.width,
					height: data.backgrounds_shape['front'].backgrounds_shape.height
				},
				back: {
					width: data.backgrounds_shape['back'].backgrounds_shape.width,
					height: data.backgrounds_shape['back'].backgrounds_shape.height
				}
			};
			this.setProductSizes(paramsSize);
			let presets = {
				front: data.backgrounds_shape['front'].backgrounds_shape,
				back: data.backgrounds_shape['front'].backgrounds_shape
			}
			this.setProductPreset(presets);
						
			// this.setProductImages(response.data.result[0].product_image);
			this.$nextTick(()=>{
				this.$refs.loadCustomerLabel.closeLabelsListPopUp();
			});
			this.emitter.emit('setDataShape', {data: this.settedShape});

			this.$nextTick(() => {
				this.$refs.layer.updatePreview();
			});
		},
		showSaveLabelPopUp() {
			this.$nextTick(()=>{
				this.$refs.saveLabelDialog.openSaveLabelPopUp();
			});
		},
		showSaveProductPopUp() {
			this.$nextTick(()=>{
				this.$refs.saveProduct.openSaveProductPopUp();
			});
		},	
		setAdditionalParams() {
			this.$canvasFront.setWidth(this.$canvasFront.width + 20);
			this.$canvasFront.setWidth(this.$canvasFront.height + 20);
			this.$canvasBack.setHeight(this.$canvasBack.width + 20);			
			this.$canvasBack.setHeight(this.$canvasBack.height + 20);
		},
 		saveLabelAs(data) {
			this.loading = true;
			let form = new FormData;
			let history = {
				front: {},
				back: {}
			};
			if (!data.labelName) {
				this.$toast.add({ severity: 'error', summary: 'Error', detail: 'The product name cannot be empty!', life: 3000 });
				this.loading = false;
				return;
			}
			/**
			 * Set zoom to 0
			 */
			if (this.zoom['front'] > 0) {
				for (let j=this.zoom['front']; j>0; j--) {
					this.decreaseCanvas('front');			
				}
			}
			if (this.zoom['back'] > 0) {
				for (let j=this.zoom['back']; j>0; j--) {
					this.decreaseCanvas('back');			
				}
			}
			if (this.zoom['front'] < 0) {
				for (let i=this.zoom['front']; i<0; i++) {
					this.increaseCanvas('front');
				}
			}
			if (this.zoom['back'] < 0) {
				for (let i=this.zoom['back']; i<0; i++) {
					this.increaseCanvas('back');
				}
			}
			let self = this;
			/**
			 * Set size for canvas
			 */
			this.$canvasFront.setWidth(this.$store.state.order.productSizes['front'].width * this.inchesToPixels);
			this.$canvasFront.setHeight(this.$store.state.order.productSizes['front'].height * this.inchesToPixels);

			this.$canvasBack.setWidth(this.$store.state.order.productSizes['back'].width * this.inchesToPixels);
			this.$canvasBack.setHeight(this.$store.state.order.productSizes['back'].height * this.inchesToPixels);
			/**
			 * Get parameters for background image
			 */
			let backgrounds = {
					front:{
						backgrounds_shape: self.settedShape['front'].backgrounds_shape,
						fill: self.settedShape['front'].fill,
						height: self.$store.state.order.productSizes['front'].height,
						width: self.$store.state.order.productSizes['front'].width
					},
					back: {
						backgrounds_shape: self.settedShape['back'].backgrounds_shape,
						fill: self.settedShape['back'].fill,
						height: self.$store.state.order.productSizes['back'].height,
						width: self.$store.state.order.productSizes['back'].width
					}
				};
			/**
			 * Set background with sizes without 20px
			 */
			fabric.loadSVGFromURL(`${this.pathUrl}/images/basic/${backgrounds['front'].width}x${backgrounds['front'].height}${backgrounds['front'].backgrounds_shape}.svg`, function(objects, options) {
				let obj = fabric.util.groupSVGElements(objects, options);
				/**
				 * Set size for canvas
				 */
				self.$canvasFront.setWidth(self.$store.state.order.productSizes['front'].width * self.inchesToPixels);
				self.$canvasFront.setHeight(self.$store.state.order.productSizes['front'].height * self.inchesToPixels);

				self.$canvasFront.backgroundImage.d = obj.d;
				self.$canvasFront.backgroundImage.path = obj.path;
				self.$canvasFront.backgroundImage.pathOffset = obj.pathOffset;
				self.$canvasFront.backgroundImage.width = self.$canvasFront.width;
				self.$canvasFront.backgroundImage.height = self.$canvasFront.height;
			});
			
			fabric.loadSVGFromURL(`${this.pathUrl}/images/basic/${backgrounds['back'].width}x${backgrounds['back'].height}${backgrounds['back'].backgrounds_shape}.svg`, function(objects, options) {
				let objBack = fabric.util.groupSVGElements(objects, options);	
				/**
				 * Set size for canvas
				 */
				self.$canvasBack.setWidth(self.$store.state.order.productSizes['back'].width * self.inchesToPixels);
				self.$canvasBack.setHeight(self.$store.state.order.productSizes['back'].height * self.inchesToPixels);

				self.$canvasBack.backgroundImage.d = objBack.d;
				self.$canvasBack.backgroundImage.path = objBack.path;
				self.$canvasBack.backgroundImage.pathOffset = objBack.pathOffset;
				self.$canvasBack.backgroundImage.width = self.$canvasBack.width;
				self.$canvasBack.backgroundImage.height = self.$canvasBack.height;
			});
			/**
			 * TODO
			 */			
			let objectsFront = self.$canvasFront.getObjects();

			for (var i in objectsFront) {
				objectsFront[i].set('left', objectsFront[i].left - 10);
				objectsFront[i].set('top', objectsFront[i].top - 10);
				if (objectsFront[i].type == 'i-text' || objectsFront[i].type == 'text-curved' || objectsFront[i].type == 'image') {
					objectsFront[i].set('calculatePositionX', (+objectsFront[i].left / +self.$canvasFront.getWidth()) * 100);
					objectsFront[i].set('calculatePositionY', (+objectsFront[i].top / +self.$canvasFront.getHeight()) * 100);
				}
				objectsFront[i].setCoords();
				if (objectsFront[i].id && (objectsFront[i].id == 'cuttingLine' || objectsFront[i].id == 'cuttingLineDashed')) {
					self.$canvasFront.remove(objectsFront[i]);
				}
			} 
			
			let objectsBack = self.$canvasBack.getObjects();
			for (var i in objectsBack) {
				objectsBack[i].set('left', objectsBack[i].left - 10);
				objectsBack[i].set('top', objectsBack[i].top - 10);
				if (objectsBack[i].type == 'i-text' || objectsBack[i].type == 'text-curved' || objectsBack[i].type == 'image') {
					objectsBack[i].set('calculatePositionX', (+objectsBack[i].left / +self.$canvasBack.getWidth()) * 100);
					objectsBack[i].set('calculatePositionY', (+objectsBack[i].top / +self.$canvasBack.getHeight()) * 100);
				}
				objectsBack[i].setCoords();
				if (objectsBack[i].id && (objectsBack[i].id == 'cuttingLine' || objectsBack[i].id == 'cuttingLineDashed')) {
					self.$canvasBack.remove(objectsBack[i]);
				}
			}
		setTimeout(() => {
			history['front'] = self.$canvasFront.toJSON(['charSpacing', 'order', 'isCustom', 'isUploaded', 'calculatePositionX', 'calculatePositionY', 'shadow', 'scaleX', 'scaleY', 'lockMovementX', 'lockMovementY', 'lockRotation', 'lockScalingX', 'lockScalingY']);
			history['back'] = self.$canvasBack.toJSON(['charSpacing', 'order', 'isCustom', 'isUploaded', 'calculatePositionX', 'calculatePositionY', 'shadow', 'scaleX', 'scaleY', 'lockMovementX', 'lockMovementY', 'lockRotation', 'lockScalingX', 'lockScalingY']);
		

			form.append('action', 'dm_save_label');
			form.append('label', JSON.stringify(history));
			form.append('name', data.labelName);
			form.append('isNewProduct', data.isNewProduct);
			
			form.append('image_front', JSON.stringify(self.$canvasFront.toDataURL()));
			form.append('image_back', JSON.stringify(self.$canvasBack.toDataURL()));	
			form.append('customer_email', this.$store.state.profileModule.userEmail);

			form.append('backgrounds_shape', JSON.stringify(backgrounds));

			if (data.labelID && data.isUsers) {
				form.append('label_id', data.labelID);
			}
			axios({
				method: 'post',
				url: this.$wpAjaxUrl,
				data: form
			}).then(response => {
				this.$nextTick(() => {
					this.$refs.alertMessage.showSuccess(response.data);					
				});
				if (response.data.slug) {
						// if (response.data.save) {
						// 	//window.location.reload();
						// 	this.$router.push(`/editor/`);
						// }else{
							// this.$router.go(this.$router.currentRoute);
							this.$router.push(`/editor/${response.data.slug}/personal`);
							this.$router.go(this.$router.currentRoute);
							// this.$router.go(`/editor/${response.data.slug}/personal`);
						// }
				} else {
					this.$router.push(`/editor/`);
				}
				this.loading = false;
			}).catch(error => {
				this.loading = false;
				this.$nextTick(() => {
					this.$refs.alertMessage.showError(error);
				});
				this.$router.push(`/editor/`);
			});
			// this.loading = false;
		}, 500);
			
		},
		getProductForEdit(data) {
			this.clearCanvases();
			if (data && data.isAdmin && data.userEmail && data.productID) {

				this.is_admin_edit = data.isAdmin;
				this.userEmail = data.userEmail;
			    this.userActiveDate = data.userActiveDate;

				let self = this;
				let form = new FormData;
				form.append('action', 'dm_get_product_for_edit');
				form.append('product_id', data.productID);
				form.append('master_id', data.masterProductID);
				axios({
					method: 'post',
					url: self.$wpAjaxUrl,
					data: form
				}).then(response => {
					let background_front = {
						backgrounds_shape: response.data.backgrounds_shape['front'].backgrounds_shape,
						fill: response.data.backgrounds_shape['front'].fill,
						height: response.data.backgrounds_shape['front'].height,
						width: response.data.backgrounds_shape['front'].width,
					};
					self.setupBgShape(background_front, 'front');
					// self.renderObjects(response.data.labels['front'].objects, 'front', false);
					self.settedShape['front'] = {
						backgrounds_shape: response.data.backgrounds_shape['front'].backgrounds_shape,
						fill: response.data.backgrounds_shape['front'].fill,
						width: response.data.backgrounds_shape['front'].width,
						height: response.data.backgrounds_shape['front'].height
					};
					
					let background_back = {
						backgrounds_shape: response.data.backgrounds_shape['back'].backgrounds_shape,
						fill: response.data.backgrounds_shape['back'].fill,
						height: response.data.backgrounds_shape['back'].height,
						width: response.data.backgrounds_shape['back'].width,
					};

					self.setupBgShape(background_back, 'back');

					self.settedShape['back'] = {
						backgrounds_shape: response.data.backgrounds_shape['back'].backgrounds_shape,
						fill: response.data.backgrounds_shape['back'].fill,
						width: response.data.backgrounds_shape['back'].width,
						height: response.data.backgrounds_shape['back'].height
					};
					// self.renderObjects(response.data.labels['back'].objects, 'back', false);

					// self.emitter.emit('setDataShape', {data: response.data.backgrounds_shape});
	
					self.settedShape.product_params.id = response.data.product_id;
					self.settedShape.product_params.name = response.data.name;
					self.settedShape.product_params.product_id = response.data.master_id;
					self.settedShape.product_params.category_id = response.data.category_id;

					//TODO  paramsSize & presets
					let paramsSize = {
							front: {
								width: response.data.backgrounds_shape['front'].width,
								height: response.data.backgrounds_shape['front'].height
							},
							back: {
								width: response.data.backgrounds_shape['back'].width,
								height: response.data.backgrounds_shape['back'].height
							}
						};
					let presets = {
							front: {
								shape: response.data.backgrounds_shape['front'].backgrounds_shape,
								fill: response.data.backgrounds_shape['front'].fill
							},
							back: {
								shape:response.data.backgrounds_shape['back'].backgrounds_shape,
								fill: response.data.backgrounds_shape['back'].fill
							} 
						};

					this.setProductName(response.data.name);
					this.setProductImages(response.data.image_front);//only one image
					this.setProductSlug(response.data.product_slug);
					this.setProductPreset(presets);
					this.setProductSizes(paramsSize);
					this.setProductStatus(response.data.is_product);

					let frontObjects = response.data.labels.front.objects;
					let backObjects = response.data.labels.back.objects;

					this.$refs.controlpanel.setDataShape({data: response.data.backgrounds_shape});
					this.$refs.controlpanel.setSizeParams({data: response.data.backgrounds_shape});
						setTimeout(()=>{
							this.renderObjects(frontObjects, 'front', false, false);
							this.renderObjects(backObjects, 'back', false, false);
							/**
							 * Need to clear history
							 */
							this.history['front'].trash = [];
							this.history['front'].current = [];
							this.history['front'].last = [];
							this.history['back'].trash = [];
							this.history['back'].current = [];
							this.history['back'].last = [];
							/**
							 * Need for start over for products with design
							 */
							this.historyColection.front = frontObjects;
							this.historyColection.back = backObjects;
							/**
							 * Need for present prewiew images and list of the layers on the right sidebar
							 */
							this.$refs.layer.renderLayers();
							this.$refs.layer.updatePreview();
						}, 300);
						
						this.$refs.cart.setTotalPrice();
				}).catch(error => {
					console.log(error);
				});
			}
		},
		getProductBySlug(slug) {
			if (this.selectedProduct || slug) {
				this.isLoaded = true;
				let self = this;
				let form = new FormData;
				if (this.$route.params.param && this.$route.params.param == 'personal') {
					form.append('action', 'dm_get_label_by_slug');
					form.append('label_slug', slug || this.$route.params.id);
					form.append('customer_email', this.$store.state.profileModule.userEmail);
				} else if(this.$route.params.param && this.$route.params.param == 'order-product-edit' && this.$route.params.data) {
					form.append('action', 'dm_get_order_label_by_slug');
					form.append('label_slug', slug || this.$route.params.id);
					form.append('order_id', parseInt(this.$route.params.data));
				} else if (slug && typeof slug == 'object' && slug.isAdmin && slug.userEmail && slug.productSlug) {
					this.is_admin_edit = slug.isAdmin;
					this.userEmail = slug.userEmail;
					form.append('action', 'dm_get_product_by_slug');
					form.append('product_slug', slug.productSlug);
				}else{
					form.append('action', 'dm_get_product_by_slug');
					form.append('product_slug', slug || this.selectedProduct);					
				}
				
				axios({
					method: 'post',
					url: self.$wpAjaxUrl,
					data: form
				}).then(response => {
					let res = null;
					if (response && response.data && response.data.result && response.data.result[0]) {
						res = response.data.result[0];
						let dataSize = {};
						this.loading = false;
						this.setProductName(res.name);
						this.setProductImages(res.image_front);//only one image
						this.setProductSlug(res.product_slug);
						let background_front = {
							backgrounds_shape: JSON.parse(res.backgrounds_shape).front.backgrounds_shape,
							fill: JSON.parse(res.backgrounds_shape).front.fill,
							height: JSON.parse(res.backgrounds_shape).front.height,
							width: JSON.parse(res.backgrounds_shape).front.width,
						};

						let background_back = {
							backgrounds_shape: JSON.parse(res.backgrounds_shape).back.backgrounds_shape,
							fill: JSON.parse(res.backgrounds_shape).back.fill,
							height: JSON.parse(res.backgrounds_shape).back.height,
							width: JSON.parse(res.backgrounds_shape).back.width,
						};
						// self.emitter.emit('setDataShape', {data: JSON.parse(response.data.result[0].backgrounds_shape)});
						
						self.settedShape['front'].backgrounds_shape = JSON.parse(res.backgrounds_shape).front.backgrounds_shape;
						self.settedShape['back'].backgrounds_shape = JSON.parse(res.backgrounds_shape).back.backgrounds_shape;
						
						self.settedShape['front'].fill = JSON.parse(res.backgrounds_shape).front.fill;
						self.settedShape['back'].fill = JSON.parse(res.backgrounds_shape).back.fill;
						
						self.settedShape['front'].height = JSON.parse(res.backgrounds_shape).front.height;
						self.settedShape['back'].height = JSON.parse(res.backgrounds_shape).back.height;

						self.settedShape['front'].width = JSON.parse(res.backgrounds_shape).front.width;
						self.settedShape['back'].width = JSON.parse(res.backgrounds_shape).back.width;

						self.data.$shapeFront = JSON.parse(res.backgrounds_shape).front.backgrounds_shape;
						self.data.$shapeBack = JSON.parse(res.backgrounds_shape).back.backgrounds_shape;

						self.settedShape.product_params = res;

						let frontObjects = JSON.parse(res.label).front.objects;
						let backObjects = JSON.parse(res.label).back.objects;

						// dataSize = {
						// 	front: {
						// 		width: JSON.parse(res.backgrounds_shape).front.width,
						// 		height: JSON.parse(res.backgrounds_shape).front.height
						// 	},
						// 	back: {
						// 		width: background_back.width,
						// 		height: background_back.height
						// 	}
						// };
						let presets = {
							front: {
								shape: background_front.backgrounds_shape,
								fill: background_front.fill
							},
							back: {
								shape: background_back.backgrounds_shape,
								fill: background_back.fill
							} 
						};
						this.setProductPreset(presets);
						this.setProductSizes({
							front:{
								width: background_front.width, 
								height: background_front.height
							},
							back: {
								width: background_back.width, 
								height: background_back.height
							}
						});
						this.setProductStatus(res.is_product);

						// self.setupBgShape(background_front, 'front');
						self.setupBgShape(background_back, 'back');
					

						self.$refs.controlpanel.setDataShape({data: JSON.parse(res.backgrounds_shape)});
						self.$refs.controlpanel.setSizeParams({data: JSON.parse(res.backgrounds_shape)});
						setTimeout(function(){
							self.renderObjects(frontObjects, 'front', false, false);
							self.renderObjects(backObjects, 'back', false, false);
							self.history['front'].trash = [];
							self.history['front'].current = [];
							self.history['front'].last = [];
							self.history['back'].trash = [];
							self.history['back'].current = [];
							self.history['back'].last = [];
							/**
							 * Need for start over for products with design
							 */
							self.historyColection.front = frontObjects;
							self.historyColection.back = backObjects;
							/**
							 * Need for present prewiew images and list of the layers on the right sidebar
							 */
							self.$refs.layer.renderLayers();
							self.$refs.layer.updatePreview();
						}, 500);
						
						this.$refs.cart.setTotalPrice();
					} else {
						let error = {
							message: 'This product does not exists!'
						};
						this.$nextTick(() => {
							this.$refs.alertMessage.showError(error);
						});
					}
				}).catch(error => {
					this.$nextTick(() => {
						this.$refs.alertMessage.showError(error);
					});
				});	
			}
		},
		// setBasicParams(canvas, side) {
		// 	this.selectedEditor(side);
		// 	if (this.zoom[side] > 0) {
		// 		for (let i=this.zoom[side]; i>=0; i--) {
		// 			this.decreaseCanvas(side);
		// 		}
		// 	}
		// 	if (this.zoom[side] < 0) {
		// 		for (let i=this.zoom[side]; i<0; i++) {
		// 			this.increaseCanvas(side);
		// 		}
		// 	}
		// 	let cloneCanvas = [];
		// 	if (this.zoom[side] == 0) {
		// 		let objectsO = Object.assign({}, canvas.getObjects());
		// 		for (var i in objectsO) {
		// 			if (objectsO[i].id && (objectsO[i].id == 'cuttingLine' || objectsO[i].id == 'cuttingLineDashed')) {
		// 				delete objectsO[i];
		// 			} else {
		// 				if (objectsO[i].type == 'border') {
		// 					if (canvas.backgroundImage.id && (canvas.backgroundImage.id == 'rect' || canvas.backgroundImage.id == 'square')) {
		// 						objectsO[i].set('left', objectsO[i].left-10);
		// 						objectsO[i].set('top', objectsO[i].top-10);	
		// 					} else {
		// 						canvas.backgroundImage.scaleToWidth(canvas.backgroundImage.width - 20);
		// 						canvas.backgroundImage.scaleToHeight(canvas.backgroundImage.height - 20);
		// 						objectsO[i].set('strokeWidth', 4);
		// 						objectsO[i].scaleToWidth(objectsO[i].width-20);
		// 						objectsO[i].scaleToHeight(objectsO[i].height-20);
		// 						objectsO[i].set('left', objectsO[i].left-2);
		// 						objectsO[i].set('top', objectsO[i].top-2);	
		// 					}
		// 				} else {		
		// 					// canvas.backgroundImage.set({
		// 					// 	width: canvas.width,
		// 					// 	height: canvas.height
		// 					// });
		// 					// canvas.backgroundImage.scaleToWidth(canvas.width);
		// 					// 	canvas.backgroundImage.scaleToHeight(canvas.height);
							
		// 					objectsO[i].set('left', objectsO[i].left - 10);
		// 					objectsO[i].set('top', objectsO[i].top - 10);
						
							
		// 				}
		// 				objectsO[i].setCoords();
		// 				cloneCanvas.push(objectsO[i]);
		// 			}
		// 		}
		// 	}
		// 	return cloneCanvas;
		// },
		saveProduct(data) {
			this.loading = true;
			let form = new FormData;
			let history = {
				front: {},
				back: {}
			};
			if (!data.productName) {
				this.$toast.add({ severity: 'error', summary: 'Error', detail: 'The product name cannot be empty!', life: 3000 });
				return;
			}
			if (!data.categoryID) { 
				this.$toast.add({ severity: 'error', summary: 'Error', detail: 'The category cannot be empty!', life: 3000 });
				return;
			}
			/**
			 * Set zoom to 0
			 */
			if (this.zoom['front'] > 0) {
				for (let j=this.zoom['front']; j>0; j--) {
					this.decreaseCanvas('front');
				}
			}
			if (this.zoom['back'] > 0) {
				for (let j=this.zoom['back']; j>0; j--) {
					this.decreaseCanvas('back');
				}
			}
			if (this.zoom['front'] < 0) {
				for (let i=this.zoom['front']; i<0; i++) {
					this.increaseCanvas('front');
				}
			}
			if (this.zoom['back'] < 0) {
				for (let i=this.zoom['back']; i<0; i++) {
					this.increaseCanvas('back');
				}
			}
			let self = this;
			/**
			 * Set size for canvas
			 */
			this.$canvasFront.setWidth(this.$store.state.order.productSizes['front'].width * this.inchesToPixels);
			this.$canvasFront.setHeight(this.$store.state.order.productSizes['front'].height * this.inchesToPixels);

			this.$canvasBack.setWidth(this.$store.state.order.productSizes['back'].width * this.inchesToPixels);
			this.$canvasBack.setHeight(this.$store.state.order.productSizes['back'].height * this.inchesToPixels);
			/**
			 * Get parameters for background image
			 */
			let backgrounds = {
					front:{
						backgrounds_shape: self.settedShape['front'].backgrounds_shape,
						fill: self.settedShape['front'].fill,
						height: self.$store.state.order.productSizes['front'].height,
						width: self.$store.state.order.productSizes['front'].width
					},
					back: {
						backgrounds_shape: self.settedShape['back'].backgrounds_shape,
						fill: self.settedShape['back'].fill,
						height: self.$store.state.order.productSizes['back'].height,
						width: self.$store.state.order.productSizes['back'].width
					}
				};
			/**
			 * Set background with sizes without 20px
			 */
			fabric.loadSVGFromURL(`${this.pathUrl}/images/basic/${backgrounds['front'].width}x${backgrounds['front'].height}${backgrounds['front'].backgrounds_shape}.svg`, function(objects, options) {
				let obj = fabric.util.groupSVGElements(objects, options);
				/**
				 * Set size for canvas
				 */
				self.$canvasFront.setWidth(self.$store.state.order.productSizes['front'].width * self.inchesToPixels);
				self.$canvasFront.setHeight(self.$store.state.order.productSizes['front'].height * self.inchesToPixels);

				self.$canvasFront.backgroundImage.d = obj.d;
				self.$canvasFront.backgroundImage.path = obj.path;
				self.$canvasFront.backgroundImage.pathOffset = obj.pathOffset;
				self.$canvasFront.backgroundImage.width = self.$canvasFront.width;
				self.$canvasFront.backgroundImage.height = self.$canvasFront.height;
			});
			
			fabric.loadSVGFromURL(`${this.pathUrl}/images/basic/${backgrounds['back'].width}x${backgrounds['back'].height}${backgrounds['back'].backgrounds_shape}.svg`, function(objects, options) {
				let objBack = fabric.util.groupSVGElements(objects, options);	
				/**
				 * Set size for canvas
				 */
				self.$canvasBack.setWidth(self.$store.state.order.productSizes['back'].width * self.inchesToPixels);
				self.$canvasBack.setHeight(self.$store.state.order.productSizes['back'].height * self.inchesToPixels);

				self.$canvasBack.backgroundImage.d = objBack.d;
				self.$canvasBack.backgroundImage.path = objBack.path;
				self.$canvasBack.backgroundImage.pathOffset = objBack.pathOffset;
				self.$canvasBack.backgroundImage.width = self.$canvasBack.width;
				self.$canvasBack.backgroundImage.height = self.$canvasBack.height;
			});
			/**
			 * TODO
			 */	
			let objectsFront = self.$canvasFront.getObjects();

			for (var i in objectsFront) {
				objectsFront[i].set('left', objectsFront[i].left - 10);
				objectsFront[i].set('top', objectsFront[i].top - 10);
				if (objectsFront[i].type == 'i-text' || objectsFront[i].type == 'text-curved' || objectsFront[i].type == 'image') {
					objectsFront[i].set('calculatePositionX', (+objectsFront[i].left / +self.$canvasFront.getWidth()) * 100);
					objectsFront[i].set('calculatePositionY', (+objectsFront[i].top / +self.$canvasFront.getHeight()) * 100);
				}
				objectsFront[i].setCoords();
				if (objectsFront[i].id && (objectsFront[i].id == 'cuttingLine' || objectsFront[i].id == 'cuttingLineDashed')) {
					self.$canvasFront.remove(objectsFront[i]);
				}
			} 
			
			let objectsBack = self.$canvasBack.getObjects();
			for (var i in objectsBack) {
				objectsBack[i].set('left', objectsBack[i].left - 10);
				objectsBack[i].set('top', objectsBack[i].top - 10);
				if (objectsBack[i].type == 'i-text' || objectsBack[i].type == 'text-curved' || objectsBack[i].type == 'image') {
					objectsBack[i].set('calculatePositionX', (+objectsBack[i].left / +self.$canvasBack.getWidth()) * 100);
					objectsBack[i].set('calculatePositionY', (+objectsBack[i].top / +self.$canvasBack.getHeight()) * 100);
				}
				objectsBack[i].setCoords();
				if (objectsBack[i].id && (objectsBack[i].id == 'cuttingLine' || objectsBack[i].id == 'cuttingLineDashed')) {
					self.$canvasBack.remove(objectsBack[i]);
				}
			}
			setTimeout(() => {
				history['front'] = self.$canvasFront.toJSON(['charSpacing', 'order', 'isCustom', 'isUploaded', 'calculatePositionX', 'calculatePositionY', 'shadow', 'scaleX', 'scaleY', 'lockMovementX', 'lockMovementY', 'lockRotation', 'lockScalingX', 'lockScalingY']);
				history['back'] = self.$canvasBack.toJSON(['charSpacing', 'order', 'isCustom', 'isUploaded', 'calculatePositionX', 'calculatePositionY', 'shadow', 'scaleX', 'scaleY', 'lockMovementX', 'lockMovementY', 'lockRotation', 'lockScalingX', 'lockScalingY']);
			
				form.append('label', JSON.stringify(history));
				form.append('action', 'dm_save_product');
				form.append('category_id', data.categoryID);
				form.append('name', data.productName);
				form.append('userEmail', self.userEmail || self.$store.state.order.adminEmail || self.$store.state.profileModule.userEmail);
				form.append('userActiveDate', self.userActiveDate);
		
				form.append('backgrounds_shape', JSON.stringify(backgrounds));

				let activeProductID = self.settedShape.product_params.id;
				let activeMasterID = self.settedShape.product_params.product_id;

				if (data.onlySave) {
					form.append('product_id', activeProductID);
				}
				if (!data.onlySave) {
					form.append('product_id', 0);
					form.append('master_id', activeMasterID != 0 ? activeMasterID : activeProductID);
				}

				form.append('image_front', JSON.stringify(self.$canvasFront.toDataURL()));
				form.append('image_back', JSON.stringify(self.$canvasBack.toDataURL()));	

				axios({
					method: 'post',
					url: this.$wpAjaxUrl,
					data: form
				}).then(response => {
					if (response.data.status == 'OK') {
						this.loading = false;
						this.$toast.add({ severity: 'info', summary: 'Success', detail: response.data.message, life: 3000 });
						if (response.data.slug) {							
							if (response.data.save) {
								this.selectedEditor('front');
								this.clearCanvases();
								this.getProductBySlug(response.data.slug);
								if (!this.$store.state.order.isAdminAddEdit) {
									this.$router.go(this.$router.currentRoute);
								}
							} else {
								this.selectedEditor('front');
								this.$router.push(`/editor/${response.data.slug}`);
							}
						}
					}
					this.loading = false;
					if (response.data.status == 'Error') {
						this.$toast.add({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
					}
				}).catch(error => {
					this.loading = false;
					console.log(error);
				});		
			}, 500);
			
		},
		checkAdmin() {
			let self = this;
			let form = new FormData;
			form.append('action', 'dm_check_is_admin');
			axios({
				method: 'post',
				url: self.$wpAjaxUrl,
				data: form
			}).then(response => {
				self.is_admin = 1;// response.data.user_id && response.data.is_admin;
				self.is_admin_edit = response.data.isAdminEdit;
			}).catch(error => {
				console.log(error);
			});
		},
		setCanvases() {
			this.container = this.$refs.container;

			this.data.$editorFront = this.$refs.editorFront;	
			this.data.$editorBack = this.$refs.editorBack;			

			fabric.Object.prototype.objectCaching = false;
			fabric.Object.prototype.noScaleCache = false;

			this.data.$canvasFront = new fabric.Canvas(this.data.$editorFront, {
				preserveObjectStacking: true
			});
			this.data.$canvasFront.setWidth(this.defaults.Canvas.width);
			this.data.$canvasFront.setHeight(this.defaults.Canvas.height);

			this.data.$canvasBack = new fabric.Canvas(this.data.$editorBack, {
				preserveObjectStacking: true
			});

			this.data.$canvasBack.setWidth(this.defaults.Canvas.width);
			this.data.$canvasBack.setHeight(this.defaults.Canvas.height);
			
			this.canvases = {
				front: {
					canvas: this.data.$canvasFront,
					editor: this.data.$editorFront,
					shape: this.data.$shapeFront,
					cuttingLine: this.data.$cuttingLineFront,
					cuttingLineDashed: this.data.$cuttingLineDashedFront,
					settedShape: 'rectangle'
				},
				back: {
					canvas: this.data.$canvasBack,
					editor: this.data.$editorBack,
					shape: this.data.$shapeBack,
					cuttingLine: this.data.$cuttingLineBack,
					cuttingLineDashed: this.data.$cuttingLineDashedBack,
					settedShape: 'rectangle'
				}
			};
			if (this.$store.state.canvas.$side == 'front' || this.data.$canvasBack.getObjects().length <= 2) {
				this.data.$activeCanvas = this.data.$canvasFront;
			} else {
				this.data.$activeCanvas = this.data.$canvasBack;
			}
			let paramsSize = {
				front: {
					width: this.settedShape.front.width,
					height: this.settedShape.front.height
				},
				back: {
					width: this.settedShape.back.width,
					height: this.settedShape.back.height
				}
			};
			let presets = {
				front: {
					shape: this.settedShape.front.backgrounds_shape,
					fill: '#FFFFFF'
				},
				back: {
					shape: this.settedShape.back.backgrounds_shape,
					fill: '#FFFFFF'
				}
			}
			this.$nextTick(()=>{
				this.$refs.layer.renderLayers();
			});
			this.setProductPreset(presets);
			this.setProductSizes(paramsSize);
			this.setProductStatus(0);
			this.$refs.cart.setTotalPrice();
		},
		preventNav(event) {
			// event.preventDefault();
			// event.returnValue = "";
		},
		setDefaultZoom() {
			this.$refs.toolbar.setDefaultZoom();
		},		
		popUpLogin() {
			this.$refs.auth.showAuthBlock();
		}
	},
	mounted() {
		let $this = this;
		window.addEventListener('message', function(event) {
			let params = event.data;
			 $this.getProductForEdit(params);
			//$this.getProductBySlug(params);
			if (params.isAdmin && params.isAdminAddEdit) {
				$this.setAdminActions(params.isAdminAddEdit);
				$this.setAdminEmail(params.userEmail);
				$this.userEmail = params.userEmail;
				$this.emitter.emit('checkIsAdmin', {isAdmin: true});
			}
		});
		// window.addEventListener('message', function(event) {			
		// 	if (event.data.action) {
		// 		$this.$refs.saveProduct.saveProduct();
		// 	}
		// });
		this.setCanvases();
		this.checkAdmin();
		this.setupBgShape('rectangle', 'front');
		this.setupBgShape('rectangle', 'back');
		
		this.setupMouseEvents();
		this.getProductBySlug();
		this.selectedEditor('front');
		this.emitter.on('saveLabelAs', (data) => this.saveLabelAs(data));
		this.emitter.on('saveProduct', (data) => this.saveProduct(data));
		this.emitter.on('showSaveLabelPopUp', (data) => this.showSaveLabelPopUp(data));
		this.emitter.on('showSaveProductPopUp', (data) => this.showSaveProductPopUp(data));
		this.emitter.on('canvasUpdated', (data) => this.canvasUpdated(data));

		this.emitter.on('addShapeFromSVG', (data) => this.addShapeFromSVG(data));
		this.emitter.on('addShapeFromPattern', (data) => this.addShapeFromPattern(data));
		this.emitter.on('addIText', (data) => this.addIText(data));
		this.emitter.on('addCurvedText', (data) => this.addCurvedText(data));
		this.emitter.on('addImage', (data) => this.addImage(data));
		this.emitter.on('addBorder', (data) => this.addBorder(data)); 
		this.emitter.on('cloneLayer', (data) => this.cloneLayer(data));
		// this.$root.$on('convertCurvedText', (object) => this.convertCurvedText(object));
		
		this.emitter.on('showImage', () => this.showImage());
		this.emitter.on('showGraphics', () => this.showGraphics());
		this.emitter.on('showUploadImages', () => this.showUploadImages());
		this.emitter.on('showImagesGallery', () => this.showImagesGallery());
		this.emitter.on('showNameLabelForSave', () => this.showNameLabelForSave());
		this.emitter.on('showNameProductForSave', () => this.showNameProductForSave());
		this.emitter.on('showShapes', () => this.showShapes());
		this.emitter.on('renderLayers', () => this.renderLayers());

		this.emitter.on('increaseCanvas', (data) => this.increaseCanvas(data));
		this.emitter.on('decreaseCanvas', (data) => this.decreaseCanvas(data));		
		this.emitter.on('layerUpAction', () => this.layerUpAction());
		this.emitter.on('layerDownAction', () => this.layerDownAction());
		// this.emitter.on('scaleBorder', (border, side) => this.scaleBorder(border, side));
		this.emitter.on('undoCanvas', () => this.undoCanvas());
		this.emitter.on('redoCanvas', () => this.redoCanvas());

		this.emitter.on('checkObjects', (data) => this.checkObjects(data));
		this.emitter.on('deleteObjects', (data) => this.deleteObjects(data));
		// this.emitter.on('deleteSelectedObjects', (data) => this.deleteSelectedObjects(data));
		
		this.emitter.on('updateHistory', (data) => this.updateHistory(data));
		this.emitter.on('layersRender', () => this.layersRender());
		this.emitter.on('showActionBlock', () => this.showActionBlock());
		this.emitter.on('startOver', () => this.startOver());
		this.emitter.on('setNewPositionForObject', () => this.setNewPositionForObject());

		this.emitter.on('selectShape', (data) => this.selectShape(data));
		this.emitter.on('setShapeColor', (data) => this.setShapeColor(data));
		this.emitter.on('addToCart', (data) => this.addToCart(data));
		this.emitter.on('selectUserLabel', (data) => this.selectUserLabel(data));
		this.emitter.on('saveLabel', () => this.saveLabel());
		this.emitter.on('selectedEditor', (data) => this.selectedEditor(data));
		this.emitter.on('setPatternGapValue', (data) => this.setPatternGapValue(data));
		this.emitter.on('setPatternSizeValue', (data) => this.setPatternSizeValue(data));
		this.emitter.on('setClippingType', (data) => this.setClippingType(data));
		// this.emitter.on('setMaterialType', (data) => this.setMaterialType(data));
		this.emitter.on('setDefaultZoom', () => this.setDefaultZoom());
		this.emitter.on('popUpLogin', () => this.popUpLogin());
		this.emitter.on('drugAndDropLayers', (data) => this.drugAndDropLayers(data));
		this.emitter.on('setActiveBackSide', (data) => this.setActiveBackSide(data));
		this.emitter.on('removeBackLayers', () => this.removeBackLayers());
	},
	beforeMount() {
      window.addEventListener("beforeunload", this.preventNav)
    },
	unmounted() {
		this.emitter.off('saveLabelAs', (data) => this.saveLabelAs(data));
		this.emitter.off('addToCart', (data) => this.addToCart(data));
		this.emitter.off('saveProduct', (data) => this.saveProduct(data));
		this.emitter.all.clear();
	},
	computed: {
		...mapState({
			$side: state => state.canvas.$side,
			$showBackCanvas: state => state.canvas.$showBackCanvas,
			statusIncludeBackLabel: state => state.order.statusIncludeBackLabel
		})
	}
}
</script>
<style scoped>
	.canvas-block{
		height: 640px;
		width: 100%;
		position: static;
		overflow: auto;
		top: 0;
		left: 0;
		background: #f4f4f4;
	}

</style>
